import { PAGED_TABLE_VIEW_SETTINGS, SUMMARY_CONFIGS } from './PagedTableViewSettings';
import { COMBINED_TABLE_GLOSSARY } from './CombinedTableGlossary';
import { TableConfigType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

export const PAGED_TABLES: { [s: string]: TableConfigType } = {
    SUBMISSIONS: {
        tableReducerPath: 'submissionsLog',
        tableName: 'submissiondata',
        apiEndpoint: 'submission_data',
        rowDataName: 'Submission',
        fetchFields: {
            // --> moved to backend
            // external_last_status_change: true,
            // insured_external_id: true,
            // _validations: true,
        },
        skipFullTableReloadOnDataUpdates: [['_validations', 'arch_clearance_api_status']],
        ignoreFieldsForExport: [
            'office_id',
            'account_agent_id',
            // 'brokerage_office_id',
            // 'account_broker_id',
            'request_underwriter_id',
            'originating_underwriter_id',
            'insured_external_id',
            'licensed_broker_id',
            'office_parent_company_id',
            'last_action',
            'status',
            '_validations',
        ],
        alwaysIncludeInGlobalSearch: ['email_subject'],
        noOptimisticDelete: true,
        viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.submission,
        tableGlossary: COMBINED_TABLE_GLOSSARY.submission,
        summaryConfigs: SUMMARY_CONFIGS,
        includeUserTableActivity: true,
        shouldTrackUserAnalytics: true,
    },
};
