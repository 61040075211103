import * as React from 'react';

import { PagedTableDataType, TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { persistRowData_server } from '@ventusrisk/jslib/src/actions/TableActions';
import { processGridRequestWithId } from '@ventusrisk/jslib/src/utils/api/RequestUtils';
import { CENTERED_MODAL_TYPES } from '@ventusrisk/jslib/src/constants/Constants';

import { SubmissionType, InboxType } from '../../ts-types/DataTypes';
import BottomPanelApp from './BottomPanelApp';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { ICUBED_MODAL_TYPES, BROKER_MATCHING_MODAL_WIDTH } from '../../constants/Modals';
import { DEFAULT_ZOOM_OUT } from '../../constants/Constants';
import { getStateFromZipcode } from '../../actions/SubmissionActions';
import { getCurrentInbox } from '../../utils/inbox-helpers';
import { openMessageModal, openCenteredModal, closeCenteredModal } from '@ventusrisk/jslib/src/actions/GlobalActions';
import { runBotResults, getSubmissionDataFromServer, getBuildingDistribution } from '../../actions/SubmissionActions';
import { connect } from 'react-redux';
import { Types } from '../../ts-types/icubed-types';
import { NoteType } from '../../ts-types/DataTypes';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';
import { VMAC_BROKER_ADD_CLICK_EVENT, VMAC_CLIENT_MATCHING_CLICK_EVENT, VMAC_ACCOUNT_SEARCH_CLICK_EVENT } from '../../constants/AnalyticsConstants';
import { updateRowData } from '@ventusrisk/jslib/src/actions/TableActions';

import { getSubmissionBuildingLocations } from '../../utils/submission-helpers';
import { isProduction, isHotfix } from '@ventusrisk/jslib/src/utils/environment-helpers';
import { deselectArchAccountForSubmission } from '../../actions/ArchActions';

const FETCHED_SUBMISSIONS = {};

type ownPropTypes = {
    tableGlossary: TableGlossaryType;
    currentSubmission: SubmissionType;
};

type propTypes = {
    me: Record<string, any>;
    currentSubmission: SubmissionType;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    tableData: PagedTableDataType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    onOpenMessageModal: (params: Record<string, any>, modalType: string) => void;
    onRunBotResults: (id: number) => void;
    onGetBuildingData: (id: number) => void;
    onUnlinkArchAccount: () => void;
    selectedRowIndex: number;
    featureFlags: any;
    currentNotes: NoteType[];
    currentInbox: InboxType;
    disableAllEdits: boolean;
    uiState: any;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { currentSubmission } = ownProps;
    const currentSubmissionId = currentSubmission.id;
    const currentInbox = getCurrentInbox(state);
    const disableAllEdits =
        (currentSubmission.is_archived && currentSubmission.is_arch_integration_enabled) ||
        (currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== state.global.me.id);

    return {
        tableData: state.submissionsLog,
        tableConfig: PAGED_TABLES.SUBMISSIONS,
        me: state.global.me,
        featureFlags: state.global.featureFlags,
        ENV: state.global.ENV,
        currentNotes: state.notes.notesById[currentSubmissionId],
        currentInbox,
        disableAllEdits,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    let { currentSubmission, tableData } = ownProps;
    const onLoadSubmissionBuildingData = (submissionId: number) => {
        return dispatch(getSubmissionDataFromServer(submissionId, ['buildings.*'])).then(() => {
            dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { id: submissionId, locationsAreLoading: false }));
        });
    };

    const onGetBuildingDistribution = (submissionId: number) => {
        return dispatch(getBuildingDistribution(submissionId));
    };
    return {
        onSaveFn: (field, value) => {
            if (field === 'assigned_to_id' && value === null && currentSubmission.assigned_to_id) {
                dispatch(
                    openCenteredModal(
                        {
                            title: 'Unassign Submission',
                            description: 'Assigned Submission can never be unassigned.',
                            modalData: {
                                confirmLabelTitle: 'Ok',
                                onOk: () => {
                                    return;
                                },
                            },
                        },
                        CENTERED_MODAL_TYPES.CONFIRM
                    )
                );
            } else {
                return dispatch(
                    persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(currentSubmission.id, { [field]: value }, tableData.rowData))
                ).then(_data => {
                    if (field === 'insured_zipcode') {
                        dispatch(getStateFromZipcode(currentSubmission.id, value));
                    }
                    dispatch(getSubmissionDataFromServer(currentSubmission.id, ['arch_account.*']));
                });
            }
        },
        onOpenMessageModal: (modalData, modalType) => {
            dispatch(openMessageModal(modalData, modalType));
        },
        onOpenCenteredModal: (modalData, modalType) => {
            dispatch(openCenteredModal(modalData, modalType));
        },
        onCloseCenteredModal: () => {
            dispatch(closeCenteredModal());
        },
        onRunBotResults: (submissionId: number) => {
            dispatch(runBotResults(submissionId));
        },
        onGetBuildingData: (submissionId: number) => {
            if (submissionId && !FETCHED_SUBMISSIONS[submissionId]) {
                FETCHED_SUBMISSIONS[submissionId] = true;
                dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { id: submissionId, locationsAreLoading: true }));
                onGetBuildingDistribution(submissionId);
                onLoadSubmissionBuildingData(submissionId);
            }
        },
        onUnlinkArchAccount: () => {
            dispatch(deselectArchAccountForSubmission(currentSubmission.id));
        },
    };
};

class BottomPanelContainer extends React.Component<propTypes> {
    static contextType = AppContext;
    logDisplayConditions = (id: string, currentSubmission: SubmissionType, me: Record<string, any>) => {
        if (isProduction() || isHotfix()) {
            this.context.analytics.track(`${id}-pressed`, {
                userId: me.id,
                assignedToId: currentSubmission.assigned_to_id,
                isSubmissionEditable: currentSubmission.is_editable,
                isSubmissionArchived: currentSubmission.is_archived,
                shouldModalOpen: !(
                    currentSubmission.is_archived ||
                    !currentSubmission.is_editable ||
                    (!!currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== me.id)
                ),
            });
        }
    };

    isCurrentSubmissionEditable = () => {
        const { me, currentSubmission } = this.props;
        return (
            !currentSubmission.is_archived && currentSubmission.is_editable && (!currentSubmission.assigned_to_id || currentSubmission.assigned_to_id === me.id)
        );
    };

    handleAccountSearch = () => {
        const { tableData, currentSubmission } = this.props;
        if (!this.isCurrentSubmissionEditable()) {
            return;
        }
        this.context.analytics.track(VMAC_ACCOUNT_SEARCH_CLICK_EVENT, {
            inbox_id: this.props.currentInbox.id,
            submission_id: currentSubmission.id,
        });
        const modalProps = {
            tableData,
            currentSubmission,
        };
        this.props.onOpenCenteredModal(modalProps, ICUBED_MODAL_TYPES.ACCOUNT_SEARCH);
    };

    handleUnlinkAccount = () => {
        this.props.onUnlinkArchAccount();
    };

    handleConflictResolution = () => {
        const { me, tableData, currentSubmission } = this.props;
        this.logDisplayConditions('lookup-insured', currentSubmission, me);
        if (!this.isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            tableData,
            currentSubmission,
        };
        this.context.analytics.track(VMAC_CLIENT_MATCHING_CLICK_EVENT, {
            submission_id: currentSubmission.id,
            inbox_id: this.props.currentInbox.id,
        });
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.CLIENT_MATCHING);
    };

    handleBrokerMatching = () => {
        const { me, tableData, currentSubmission } = this.props;
        this.logDisplayConditions('lookup-broker', currentSubmission, me);
        if (!this.isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            tableData: tableData,
            width: BROKER_MATCHING_MODAL_WIDTH,
            currentSubmission: currentSubmission,
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.BROKER_MATCHING);
    };

    handleAddAccountBroker = () => {
        const { currentSubmission } = this.props;
        if (!this.isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            currentSubmissionId: currentSubmission.id,
            width: BROKER_MATCHING_MODAL_WIDTH,
        };
        this.context.analytics.track(VMAC_BROKER_ADD_CLICK_EVENT, { submission_id: this.props.currentSubmission.id, inbox_id: this.props.currentInbox.id });
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.ADD_ACCOUNT_BROKER);
    };

    handleViewBuildingDistributions = () => {
        let { tableData, currentSubmission } = this.props;
        this.props.onGetBuildingData(currentSubmission.id);
        let props = {
            currentSubmission: currentSubmission,
            tableData: tableData,
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.BUILDING_DISTRUBTIONS);
    };

    handleAddOrEditNote = note => {
        const { currentSubmission } = this.props;
        const isNewNote = note.id === undefined;
        const isDraftNote = note.is_draft === true;

        const props = {
            modalData: {
                saveParams: {
                    submission_id: currentSubmission.id,
                    note_type: 'UN',
                },
                idKey: currentSubmission.id,
                includeSubject: true,
                note: note,
                readOnly: !isNewNote && !isDraftNote,
                draftModeEnaled: isNewNote || isDraftNote,
            },
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.ADD_NOTE);
    };

    handleRunBot = _note => {
        this.props.onRunBotResults(this.props.currentSubmission.id);
    };

    handleScheduleView = () => {
        const { selectedRowIndex, currentSubmission, onGetBuildingData, onOpenCenteredModal } = this.props;
        const { rowData } = this.props.tableData;
        onGetBuildingData(currentSubmission.id);
        const locations = getSubmissionBuildingLocations(currentSubmission.id, rowData);
        const locationEditorProps = {
            locations,
            zoom: DEFAULT_ZOOM_OUT,
            editable: false,
            showNavArrows: false,
            currentIndex: selectedRowIndex,
            itemTypeName: 'Building',
            showClose: true,
        };
        onOpenCenteredModal(locationEditorProps, ICUBED_MODAL_TYPES.MAP_SCHEDULE_VIEW);
    };

    handleViewOfacResults = () => {
        let { currentSubmission } = this.props;
        let ofac_data = 'There is no OFAC data to display.';
        if (currentSubmission.arch_account && currentSubmission.arch_account.cached_ofac_api_response) {
            ofac_data = JSON.stringify(currentSubmission.arch_account.cached_ofac_api_response, null, 2);
        }
        let ofacResultsProps = {
            title: ofac_data,
        };
        this.props.onOpenMessageModal(ofacResultsProps, CENTERED_MODAL_TYPES.CONFIRM);
    };

    render() {
        return (
            <BottomPanelApp
                {...this.props}
                onHandleAccountSearch={this.handleAccountSearch}
                onHandleUnlinkAccount={this.handleUnlinkAccount}
                onHandleConflictResolution={this.handleConflictResolution}
                onHandleBrokerMatching={this.handleBrokerMatching}
                onHandleAddAccountBroker={this.handleAddAccountBroker}
                onHandleAddOrEditNote={this.handleAddOrEditNote}
                onHandleViewOfacResults={this.handleViewOfacResults}
                onHandleViewBuildingDistributions={this.handleViewBuildingDistributions}
                onHandleRunBot={this.handleRunBot}
                onPopScheduleView={this.handleScheduleView}
            />
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BottomPanelContainer);
export default connected;
