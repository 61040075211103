import * as React from 'react';
import { connect } from 'react-redux';

import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

import SubmissionConflictsApp from '../apps/SubmissionConflictsApp';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, PromiseDispatchType, VoidFn } from '../../../../ts-types/DataTypes';
import { getSubmissionConflicts } from '../../../../actions/ArchActions';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { resetTable } from '@ventusrisk/jslib/src/actions/TableActions';
import { COMBINED_TABLE_GLOSSARY } from '../../../../constants/CombinedTableGlossary';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

type propTypes = {
    CONSTANTS: Types.Constants;
    tableGlossary: TableGlossaryType;
    onGetSubmissionConflicts: () => PromiseDispatchType;
    resetTable: VoidFn;
    ENV: Types.Env;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { ENV } = state.global;

    return {
        ENV,
        uiState: state.uiState,
        tableData: state.submissionConflicts,
        CONSTANTS: state.global.CONSTANTS,
        tableGlossary: COMBINED_TABLE_GLOSSARY.conflictingsubmissions,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onGetSubmissionConflicts: () => {
            return dispatch(getSubmissionConflicts(ownProps.currentSubmission.id));
        },
        resetTable: () => {
            dispatch(resetTable(LITE_TABLES.SUBMISSIONCONFLICTS));
        },
    };
};

class SubmissionConflicts extends React.Component<propTypes> {
    componentDidMount() {
        this.props.onGetSubmissionConflicts();
    }
    render() {
        return <SubmissionConflictsApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(SubmissionConflicts);
export default connected;
