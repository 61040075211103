import * as React from 'react';
import _ from 'lodash';

import LiteTableContainer from '@ventusrisk/jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { SubmissionType, VoidFn } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { ButtonInput, TextInput } from '@ventusrisk/jslib/src/components/inputs';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';

import { VMAC_BROKER_MATCHING_SEARCH_EVENT, VMAC_BROKER_MATCHING_SWITCHING_CLIENT } from '../../../../constants/AnalyticsConstants';

type propTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onPersistRowData: (field: string, value: any) => void;
    onGetMatchedBrokers: (s: string) => void;
    resetTable: VoidFn;
    currentProducingAgentName: string | null;
};

type stateTypes = {
    searchValue: string;
};

export default class BrokerMatchingApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            searchValue: props.currentProducingAgentName || props.currentSubmission.account_agent_name_as_parsed,
        };

        this.getMatches();
    }

    componentWillUnmount() {
        this.props.resetTable();
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    getMatches = _.debounce(() => {
        if (this.state.searchValue) {
            this.props.onGetMatchedBrokers(this.state.searchValue);
        }
    }, 500);

    handleChange = (field: string, value: any) => {
        this.setState({ searchValue: value });
        this.getMatches();
        this.context.analytics.track(VMAC_BROKER_MATCHING_SEARCH_EVENT, { searchTerm: value });
    };

    onSelectNewBrokerMatch = (newBrokerId: number) => {
        if (newBrokerId !== this.props.currentSubmission.account_agent_id) {
            this.props.onPersistRowData('account_agent_id', newBrokerId);
            this.context.analytics.track(VMAC_BROKER_MATCHING_SWITCHING_CLIENT, {
                oldBrokerId: this.props.currentSubmission.account_agent_id,
                submission_id: this.props.currentSubmission.id,
                newBrokerId,
            });
        } else {
            console.log('Already selected');
        }

        this.props.onClose(true);
    };

    resetSearchValue = () => {
        this.setState({ searchValue: this.props.currentSubmission.account_agent_name_as_parsed });
        this.getMatches();
    };

    render() {
        let { currentSubmission, tableData } = this.props;
        if (!currentSubmission) {
            return null;
        }
        return (
            <React.Fragment>
                <TextInput
                    maxLength={100}
                    className="client-matches-search label-above"
                    name="searchValue"
                    label="Broker Name (edit to do a new search)"
                    onEveryChange={this.handleChange}
                    object={this.state}
                />
                <ButtonInput
                    label="Broker Name as parsed from email (click to reset search)"
                    className="matches-as-parsed label-above"
                    name="account_agent_name_as_parsed"
                    disabled={!currentSubmission.account_agent_name_as_parsed || this.state.searchValue === currentSubmission.account_agent_name_as_parsed}
                    onClick={this.resetSearchValue}
                    object={currentSubmission}
                />
                <LiteTableContainer
                    onRowSelected={null}
                    hasActionPanel={false}
                    tableConfig={LITE_TABLES.BROKERMATCHES}
                    selectedRowId={currentSubmission.account_agent_id}
                    containerClass="standard-modal-content"
                    tableData={tableData}
                    actionHelperFns={{
                        onSelectNewMatch: this.onSelectNewBrokerMatch,
                    }}
                    extraCellRenderData={{
                        currentSubmission,
                        field_name: 'account_agent_id',
                    }}
                />
            </React.Fragment>
        );
    }
}
