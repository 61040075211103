import * as React from 'react';

import { InboxType, SubmissionType, VoidFn } from '../../ts-types/DataTypes';

import LeftPanel from './LeftPanel';
import TopPanel from './TopPanel';

import classNames from 'classnames';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    children: React.ReactNode;
    currentInbox: InboxType;
    currentSubmission?: SubmissionType;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    onTogglePanel: (panel: string) => void;
    onLogOut: VoidFn;
    openPanels: Record<string, any>;
    inboxes: InboxType[];
    ENV: Types.Env;
    uiState: Record<string, any>;
    featureFlags: Record<string, any>;
};

class PanelView extends React.Component<propTypes> {
    render() {
        return (
            <>
                <div className={classNames('main-content')}>
                    <LeftPanel
                        inboxes={this.props.inboxes}
                        currentInbox={this.props.currentInbox}
                        onSelectInbox={this.props.onSelectInbox}
                        onTogglePanel={this.props.onTogglePanel}
                        onLogOut={this.props.onLogOut}
                        openPanels={this.props.openPanels}
                        featureFlags={this.props.featureFlags}
                    />
                    {this.props.children}
                </div>
                <TopPanel
                    currentSubmission={this.props.currentSubmission}
                    ENV={this.props.ENV}
                    uiState={this.props.uiState}
                    openPanels={this.props.openPanels}
                    featureFlags={this.props.featureFlags}
                />
            </>
        );
    }
}

export default PanelView;
