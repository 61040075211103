import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import DuplicatesTable from '../containers/DuplicatesTable.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { DefaultHeaderWithProps } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    tableData: LiteTableDataType;
    currentSubmission: SubmissionType;
};

class DuplicatesTableBody extends React.Component<bodyPropTypes> {
    render() {
        return (
            <div>
                <legend className="duplicate-legend-wrapper">
                    <div className="badge badge-purple"></div>
                    <div className="purple-txt">VMAC</div>
                    <div className="badge badge-primary"></div>
                    <div className="blue-txt">VIKI</div>
                    <div className="badge badge-grey"></div>
                    <div className="grey-txt">ARCH</div>
                </legend>
                <DuplicatesTable {...this.props} />
            </div>
        );
    }
}

const modalTypeObj = {
    Body: DuplicatesTableBody,
    Header: DefaultHeaderWithProps('Possible Duplicate Submissions'),
    className: 'duplicate-submission-modal',
};

registerType(ICUBED_MODAL_TYPES.DUPLICATE_SUBMISSIONS, modalTypeObj);
export default modalTypeObj;
