import { API } from '@ventusrisk/jslib';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { updateDataByPath, loadLiteTableRows } from '@ventusrisk/jslib/src/actions/TableActions';
import * as types from '../constants/ActionTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';

export const setClientMatches = (accountId: number, accountStatues: any[], submissionStatuses: any[]) => {
    return {
        type: types.SET_ARCH_API_STATUS,
        accountId,
        accountStatues,
        submissionStatuses,
    };
};

export const getSubmissionConflicts = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            submissionId,
        };
        return API.doRequest(API.endpoints.arch.getSubmissionConflicts, request, dispatch).then(({ data }) => {
            dispatch(loadLiteTableRows(LITE_TABLES.SUBMISSIONCONFLICTS, data));
        });
    };
};

export const getAccountConflicts = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            submissionId,
            params: {},
            hideDialogForErrors: true,
        };
        return API.doRequest(API.endpoints.arch.getAccountConflicts, request, dispatch).then(({ data }) => {
            dispatch(loadLiteTableRows(LITE_TABLES.ACCOUNTCONFLICTS, data));
            return data;
        });
    };
};

export const getApiStatuses = (accountId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            accountId,
        };
        return API.doRequest(API.endpoints.arch.getStatuses, request, dispatch).then(({ data: { api_status, data_check, account, submission } }) => {
            dispatch(
                updateDataByPath(PAGED_TABLES.SUBMISSIONS, `rowData.${accountId}`, {
                    apiStatuses: { account, submission, dataCheckStatuses: data_check, globalStatus: api_status },
                })
            );
        });
    };
};

export const markRowAsSelected = (rowId: number): any => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        const rows = getState().accountConflicts.rows;
        for (let i = 0; i < rows.length; ++i) {
            dispatch(
                updateDataByPath(LITE_TABLES.ACCOUNTCONFLICTS, `rowData.${rows[i]}`, {
                    selected: false,
                })
            );
        }
        return dispatch(
            updateDataByPath(LITE_TABLES.ACCOUNTCONFLICTS, `rowData.${rowId}`, {
                selected: true,
            })
        );
    };
};

export const retrySubmissionWithArch = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        const request = { submissionId };
        return API.doRequest(API.endpoints.arch.retrySubmissionWithArch, request, dispatch);
    };
};

export const resolveArchAccountConflict = (submissionId: number, accountId: string) => {
    return (dispatch: DispatchType) => {
        const request = { submissionId, params: { accountId } };
        return API.doRequest(API.endpoints.arch.resolveAccountConflict, request, dispatch);
    };
};

export const selectArchAccountForSubmission = (submissionId: number, archAccountId: string) => {
    return (dispatch: DispatchType) => {
        const request = { params: { submissionId, arch_account_arch_id: archAccountId } };
        return API.doRequest(API.endpoints.submission.selectArchAccount, request, dispatch);
    };
};

export const deselectArchAccountForSubmission = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        const request = { params: { submissionId } };
        return API.doRequest(API.endpoints.submission.deselectArchAccount, request, dispatch);
    };
};

export const searchArchSubmissions = (account_id: string) => {
    return (dispatch: DispatchType) => {
        const request = { params: { account_id } };
        console.log(`searching for arch accounts with ${account_id}`);
        return API.doRequest(API.endpoints.arch.searchArchSubmissions, request, dispatch).then(
            response => {
                console.log(response, response.data);
                dispatch(loadLiteTableRows(LITE_TABLES.BORSUBMISSIONS, response.data));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};
