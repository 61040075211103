import * as React from 'react';
import SubmissionConflicts from '../containers/SubmissionConflicts.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onGetDeltaRecords: () => void;
    insuredReferences: Record<string, any>[];
};

class SubmissionConflictsBody extends React.Component<bodyPropTypes> {
    render() {
        return <SubmissionConflicts {...this.props} />;
    }
}

type headerPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onGetDeltaRecords: () => void;
};

class SubmissionConflictsHeader extends React.Component<headerPropTypes> {
    render() {
        let { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Submission Conflicts for ${currentSubmission.id} - ${currentSubmission.arch_account_name}`);
    }
}

const modalTypeObj = {
    Body: SubmissionConflictsBody,
    Header: SubmissionConflictsHeader,
    className: 'debug-info-modal wide',
};

registerType(ICUBED_MODAL_TYPES.SUBMISSION_CONFLICTS, modalTypeObj);
export default modalTypeObj;
