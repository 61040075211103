import * as React from 'react';

import { blockPropTypes } from '../../../../ts-types/DataTypes';
import { TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import InfoBlock from '@ventusrisk/jslib/src/components/blocks/InfoBlock';
import { processColumnsForInfoBlocks } from '@ventusrisk/jslib/src/utils/tables/columns';
import { CLEARANCE_INFOBLOCKS } from '../../../../constants/InfoBlocks';
import { Types } from '../../../../ts-types/icubed-types';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';

type propTypes = {
    onClose: (close: boolean) => void;
    tableGlossary: TableGlossaryType;
    CONSTANTS: Types.Constants;
};

const INFO_BLOCKS: blockPropTypes[] = [CLEARANCE_INFOBLOCKS.dist_by_construction, CLEARANCE_INFOBLOCKS.dist_by_occupancy];

export default class BuildingDistributionsApp extends React.Component<propTypes> {
    static contextType = AppContext;

    handleCancel = () => {
        this.props.onClose(true);
    };

    renderBlocks() {
        const { tableGlossary, CONSTANTS } = this.props;
        const defs = processColumnsForInfoBlocks(CLEARANCE_INFOBLOCKS, tableGlossary, CONSTANTS);

        const blocks = [];
        for (let block of INFO_BLOCKS) {
            blocks.push(<InfoBlock key={block.header} glossary={defs} parentProps={this.props} {...block} />);
        }
        return blocks;
    }

    render() {
        let blocks = this.renderBlocks();
        console.assert(blocks.length === 2, 'You forgot an infoblock!');
        return (
            <div className="standard-modal-content flex column blgd-dist-modal-content">
                {blocks[0]}
                {blocks[1]}
            </div>
        );
    }
}
