import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { PANELS } from '@ventusrisk/jslib/src/constants/Constants';
import NavIcon from '@ventusrisk/jslib/src/components/widgets/NavIcon';
import VikiLogo from '@ventusrisk/jslib/src/components/widgets/VikiLogo';
import DebugInfo from '@ventusrisk/jslib/src/components/widgets/DebugInfo';
import { flip } from '@ventusrisk/jslib/src//utils/updeep-helpers';
import OtherUsersViewingPanel from '@ventusrisk/jslib/src/components/panels/OtherUsersViewingPanel';
import { setUserSettings } from '@ventusrisk/jslib/src/actions/UserActions';

import { getAccountNumberFromString } from '@ventusrisk/jslib/src/utils/arch-helpers';
import { SubmissionType, UnderwritingPlatformType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';
import { useUpdateUserSettingsMutation } from '../../services/apiSlice';
import { CLEARANCE_STATUS } from '../../constants/Constants';

type propTypes = {
    currentSubmission: SubmissionType;
    ENV: Types.Env;
    uiState: any;
    openPanels: any;
    featureFlags: Record<string, string>;
};

const TopPanel = (props: propTypes) => {
    const dispatch = useDispatch();
    const userSettings = useSelector((state: Types.RootState) => state.global.userSettings);
    const sharedContext = useContext(AppContext);
    const [updateUserSettings /*{ isLoading: isUpdatingUserSettings }*/] = useUpdateUserSettingsMutation();

    const handleToggleLeftPanel = () => {
        // http://stackoverflow.com/a/31899998/237091
        sharedContext.onTogglePanel(PANELS.LEFT);
        setTimeout(() => {
            updateUserSettings({ data: { left_panel_opened: !props.openPanels[PANELS.LEFT] } }).then(({ data }: any) => {
                dispatch(setUserSettings([data]));
            });
        }, 500);
    };

    const renderSubmissionInfo = () => {
        const { currentSubmission, ENV, featureFlags } = props;

        if (!currentSubmission || _.isEmpty(currentSubmission) || !currentSubmission.id) {
            return (
                <div className="submission-info">
                    <div className="status-area main-label">Submissions</div>
                </div>
            );
        }
        const { status, date_cleared, forwarded_by, date_forwarded, submission_cleared_by, last_user_action_failed, last_user_action_failed_action_type } =
            currentSubmission;
        const clearByInfo = submission_cleared_by && date_cleared ? `${moment(date_cleared).format('MM.DD.YY')} / ${submission_cleared_by}` : 'NOT YET CLEARED';
        const fwdByInfo = date_forwarded && forwarded_by ? `${moment(date_forwarded).format('MM.DD.YY')} / ${forwarded_by}` : 'NOT YET';
        const overViewUrl = `submissions/${currentSubmission.external_sid}/current/overview`;
        const archLink = `${ENV.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
            currentSubmission.arch_clearance_api_submission_id
        )}`;

        let lastActionFailedBadge = null;
        if (last_user_action_failed && featureFlags.needs_reconciliation) {
            let lastFailedUserAction = {
                [CLEARANCE_STATUS.CLEARED]: 'clear',
                [CLEARANCE_STATUS.BLOCKED]: 'block',
                [CLEARANCE_STATUS.DECLINED]: 'decline',
            }[last_user_action_failed_action_type];
            lastActionFailedBadge = <div className="status-badge red">{`FAILED ${lastFailedUserAction}`}</div>;
        }

        let vikiUrl = null;
        let externalPlatformLabel = 'VIKI';
        if (currentSubmission.external_sid) {
            vikiUrl = ENV.VIKI_BASE_URL;
            if (featureFlags.write_to_rae_enabled && currentSubmission.underwriting_platform === UnderwritingPlatformType.POLICYWRITER) {
                externalPlatformLabel = 'RAE';
                vikiUrl = ENV.RAE_BASE_URL;
            }
            vikiUrl += `/${overViewUrl}`;
        }

        return (
            <div className="submission-info">
                <div className="submission-details">
                    <If condition={status !== undefined}>
                        <div className="status-badge">{currentSubmission.status_display}</div>
                    </If>
                    {lastActionFailedBadge}
                    <div className="submission-id-details">
                        <div className="info-figure">
                            <span className="info-label">ID:</span>
                            <span className="info-data">{currentSubmission.id}</span>
                        </div>
                        <If condition={currentSubmission.external_sid}>
                            <div className="info-figure">
                                <span className="info-label">{externalPlatformLabel} SID:</span>
                                <span className="info-data">
                                    <a className="text-button-simple" target="_blank" href={vikiUrl}>
                                        {currentSubmission.external_sid}
                                    </a>
                                </span>
                            </div>
                        </If>
                        <If condition={!!currentSubmission.arch_clearance_api_submission_id}>
                            <div className="info-figure">
                                <span className="info-label">ARCH ID:</span>
                                <span className="info-data">
                                    <a className="text-button-simple" target="_blank" href={archLink}>
                                        {currentSubmission.arch_clearance_api_submission_id}
                                    </a>
                                </span>
                            </div>
                        </If>
                        <div className="info-figure">
                            <span className="info-label">DATE CLEARED / BY:</span>
                            <span className="info-data">{clearByInfo}</span>
                        </div>
                        <div className="info-figure">
                            <span className="info-label">DATE SENT / BY:</span>
                            <span className="info-data">{fwdByInfo}</span>
                        </div>
                    </div>
                </div>
                <If condition={currentSubmission.arch_account_name !== undefined}>
                    <div className="main-label ellipsize">{currentSubmission.arch_account_name}</div>
                </If>
            </div>
        );
    };

    const { currentSubmission } = props;
    const submissionInfo = renderSubmissionInfo();
    const currentViewers = props.currentSubmission._current_row_viewers;
    const displayFullHeader = currentSubmission && currentSubmission.status_display;

    return (
        <>
            <div
                key="header-main"
                className={classNames('header-main', {
                    'env-not-production': true, // TODO: showDebugPanel,
                })}
            >
                <div className="header-left" key="header-logo">
                    <NavIcon open={userSettings.left_panel_opened} onClick={handleToggleLeftPanel} />
                    <VikiLogo title="VMAC" subtitle="Clearance" />
                </div>
                <div className="header-right">
                    {submissionInfo}
                    <If condition={displayFullHeader}>
                        <OtherUsersViewingPanel currentViewers={currentViewers} />
                    </If>
                </div>
            </div>
            <DebugInfo
                key="debug-info"
                ENV={props.ENV}
                open={props.uiState.TopBar.debugOpen}
                toggleDebugBar={() => sharedContext.setUiState({ debugOpen: flip }, 'TopBar')}
            />
        </>
    );
};

export default TopPanel;
