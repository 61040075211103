import * as React from 'react';
import _ from 'lodash';
import { MouseEventHandler } from 'react';

import InfoBlock from '@ventusrisk/jslib/src/components/blocks/InfoBlock';
import { processColumnsForInfoBlocks } from '@ventusrisk/jslib/src/utils/tables/columns';
import { PagedTableDataType, TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import ConfirmPromptButton from '@ventusrisk/jslib/src/components/popups/ConfirmPrompt';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';
import { getTaskPath } from '@ventusrisk/jslib/src/utils/pusher-helpers';
import { VMAC_BOTTOM_PANEL_EVENT } from '../../constants/AnalyticsConstants';
import { InboxType, SubmissionType } from '../../ts-types/DataTypes';
import { CLEARANCE_INFOBLOCKS } from '../../constants/InfoBlocks';
import { blockPropTypes, NoteType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import ThrottledButton from '@ventusrisk/jslib/src/components/buttons/ThrottledButton';

type propTypes = {
    header?: string;
    readonly?: boolean;
    defs?: any;
    currentSubmission: SubmissionType;
    currentNotes: NoteType[];
    currentInbox: InboxType;
    onSaveFn?: (key: string, value: any) => void;
    onPopScheduleView?: MouseEventHandler<HTMLButtonElement>;
    onHandleAccountSearch?: MouseEventHandler<HTMLButtonElement>;
    onHandleConflictResolution?: MouseEventHandler<HTMLButtonElement>;
    onHandleUnlinkAccount?: MouseEventHandler<HTMLButtonElement>;
    onHandleBrokerMatching?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddAccountBroker?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddOrEditNote?: MouseEventHandler<HTMLButtonElement>;
    onHandleViewOfacResults?: MouseEventHandler<HTMLButtonElement>;
    onHandleRunBot: MouseEventHandler<HTMLButtonElement>;
    onHandleViewBuildingDistributions: MouseEventHandler<HTMLButtonElement>;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    featureFlags: any;
    tableData: PagedTableDataType;
    tableGlossary: TableGlossaryType;
    disableAllEdits: boolean;
    me: Record<string, any>;
    uiState: any;
};

const INFO_BLOCKS: blockPropTypes[] = [
    CLEARANCE_INFOBLOCKS.broker,
    CLEARANCE_INFOBLOCKS.insured,
    CLEARANCE_INFOBLOCKS.dates,
    CLEARANCE_INFOBLOCKS.sov_information,
    CLEARANCE_INFOBLOCKS.general_notes,
    CLEARANCE_INFOBLOCKS.submission_assignment,
    CLEARANCE_INFOBLOCKS.ofac,
    CLEARANCE_INFOBLOCKS.target_terms,
    CLEARANCE_INFOBLOCKS.conflicting_information,
];

type stateTypes = {
    insuredEditableOverride: boolean;
};

export default class BottomPanelApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            insuredEditableOverride: false,
        };
    }

    enableOverride = () => {
        this.setState({ insuredEditableOverride: true });
    };

    handleOnSave = (field: string, value: any) => {
        const { onSaveFn, currentSubmission, currentInbox } = this.props;
        this.context.analytics.track(VMAC_BOTTOM_PANEL_EVENT, {
            [field]: value,
            value_before: this.props.tableData.rowData[currentSubmission.id][field],
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
        onSaveFn(field, value);
    };

    renderBlocks() {
        const { uiState, disableAllEdits, tableGlossary, onSaveFn, CONSTANTS, currentSubmission, onHandleConflictResolution, onHandleAddOrEditNote } =
            this.props;
        const showEditButton = currentSubmission.arch_account_id && !this.state.insuredEditableOverride;
        const readonly = currentSubmission.props && !!currentSubmission.props.readonly;
        const defs = processColumnsForInfoBlocks(CLEARANCE_INFOBLOCKS, tableGlossary, CONSTANTS);
        const blocks = [];

        for (let block of INFO_BLOCKS) {
            if (block.hideBlock && block.hideBlock(this.props)) {
                continue;
            }

            let perBlockShowEditButton = true;
            const isInsuredBlock = block.enableClientMatching;
            const isArchAccount = currentSubmission.arch_account && currentSubmission.arch_account_arch_id;
            const isSuspendedArchAccount =
                isArchAccount && currentSubmission.arch_account.status && currentSubmission.arch_account.status.toLowerCase().startsWith('s');
            // disable insured edits if arch account is linked and not suspended
            if (isInsuredBlock && (!isArchAccount || isSuspendedArchAccount)) {
                perBlockShowEditButton = false;
            }

            perBlockShowEditButton = perBlockShowEditButton && showEditButton;

            const customHeaderButtons = [];
            let { headerButtons } = block;
            if (headerButtons) {
                headerButtons = typeof headerButtons === 'function' ? headerButtons(this.props) : headerButtons;
                for (let i = 0; i < headerButtons.length; i++) {
                    const isDisabled = headerButtons[i].disabledFn && headerButtons[i].disabledFn(currentSubmission);
                    if (this.props[headerButtons[i].callbackFn] && headerButtons[i].buttonName && !isDisabled) {
                        customHeaderButtons.push(
                            <ThrottledButton
                                throttleSeconds={headerButtons[i].throttleSeconds}
                                key={i}
                                label={headerButtons[i].buttonName}
                                onClick={this.props[headerButtons[i].callbackFn]}
                            />
                        );
                    }
                }
            }

            if (!disableAllEdits && block.enableClientMatching && onHandleConflictResolution) {
                if (perBlockShowEditButton && currentSubmission.is_editable) {
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="edit"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: this.enableOverride,
                                confirmButtonTitle: 'Edit',
                                title: 'Edit this insured to update information - to add a new insured use Create New',
                            }}
                        >
                            Edit
                        </ConfirmPromptButton>
                    );
                }
            }

            const isAssignmentBlock = block.header === 'Assignment';
            const isConflictingInfoBlock = block.header === 'Conflicting Info';
            let progressTaskInfo = uiState ? _.get(uiState, getTaskPath('PUBLISH_TO_GATEWAY', currentSubmission.id)) || {} : {};
            const disabled = disableAllEdits && !isAssignmentBlock && !isConflictingInfoBlock && !progressTaskInfo.in_progress;

            blocks.push(
                <InfoBlock
                    key={block.header}
                    disabled={disabled}
                    readonly={!onSaveFn || readonly || disabled}
                    glossary={defs}
                    rowObjectGetter="currentSubmission"
                    parentProps={this.props}
                    customHeaderButtons={customHeaderButtons.length ? <div className="header-buttons">{customHeaderButtons}</div> : null}
                    {...block}
                    extraData={{ ...this.state, me: this.props.me, featureFlags: this.props.featureFlags }}
                    helperFunctions={{
                        handleSave: this.handleOnSave,
                        handleAddRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                        handleEditRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                    }}
                />
            );
        }
        return blocks;
    }

    render() {
        let { header, currentSubmission } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <React.Fragment>
                <If condition={header}>
                    <div className="abs top-left flex handles">
                        <div className="panel-label handle">
                            <div className="text">{header}</div>
                        </div>
                    </div>
                </If>

                <div className="panel-area w-padding">
                    <div className="grid-layout gl-2">{this.renderBlocks()}</div>
                </div>
            </React.Fragment>
        );
    }
}
