import * as React from 'react';
import u from 'updeep';

import { Types } from '../../../../ts-types/icubed-types';
import { TextInput, CheckInput } from '@ventusrisk/jslib/src/components/inputs';
import { SubmissionType, PromiseDispatchType } from '../../../../ts-types/DataTypes';

type propTypes = {
    onClose: (close: boolean) => void;
    onAddOverrideReason: (reason: string, sid: number[]) => PromiseDispatchType;
    performClearanceAction: (reason: string) => void;
    currentSubmission: SubmissionType;
    action: string;
    ENV: Types.Env;
};

type stateTypes = {
    overrideInformation: Record<string, any>;
    blockingSids: Record<string, any>;
};

export default class OverrideBusinessRulesApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            overrideInformation: { reason: '' },
            blockingSids: Object.assign({}, ...props.currentSubmission.blocking_submission_ids.map(id => ({ [id]: true }))),
        };
    }

    _clearForm() {
        this.setState({ overrideInformation: { reason: '' } });
    }

    handleChange = (fieldName: string, value: any) => {
        let { overrideInformation } = this.state;

        this.setState({
            overrideInformation: u({ [fieldName]: value }, overrideInformation),
        });
    };

    handleAddOverrideReason = () => {
        let { action, performClearanceAction } = this.props;
        let { blockingSids } = this.state;
        let overrideInformation = this.state.overrideInformation;
        let toBlockSids = [];
        for (const [key, value] of Object.entries(blockingSids)) {
            if (value) {
                toBlockSids.push(parseInt(key, 10));
            }
        }
        this.props
            .onAddOverrideReason(overrideInformation.reason, toBlockSids)
            .then(_response => {
                this._clearForm();
                this.handleCancel();
                performClearanceAction(action);
            })
            .catch(xhr => {
                this.setState({
                    overrideInformation: u({ _errors: xhr.data.errors }, overrideInformation),
                });
                return true;
            });
    };

    handleCancel = () => {
        this._clearForm();
        this.props.onClose(true);
    };

    handleSelectBlockingSubmission = (fieldName: string, value: any) => {
        let newBlocking = u({ [fieldName]: value }, this.state.blockingSids);
        this.setState({
            blockingSids: newBlocking,
        });
    };

    getBlockingDialogue = () => {
        let { ENV, currentSubmission } = this.props;
        let rows = currentSubmission.blocking_submission_ids.map((bSid, idx) => {
            return (
                <a target="_blank" key={idx} href={`${ENV.VIKI_BASE_URL}/submissions/${bSid}/current/overview`}>
                    <CheckInput
                        label={`Submission ${bSid}`}
                        labelAfter={true}
                        className="width-20"
                        object={this.state.blockingSids}
                        name={`${bSid}`}
                        defaultValue={false}
                        onChange={this.handleSelectBlockingSubmission}
                    />
                </a>
            );
        });

        return (
            <div>
                The following submissons will be marked as declined in VIKI:
                <br />
                {rows}
                <br />
                <br />
            </div>
        );
    };

    getIncumbentDialogue = () => {
        let { ENV, currentSubmission } = this.props;
        let rows = currentSubmission.incumbent_submission_ids.map((bSid, idx) => {
            return (
                <a target="_blank" key={idx} href={`${ENV.VIKI_BASE_URL}/submissions/${bSid}/current/overview`}>
                    Submission {bSid}
                </a>
            );
        });

        return (
            <div>
                The following submissions CANNOT be marked as declined in VIKI because they are already bound:
                <br />
                {rows}
                <br />
                <br />
            </div>
        );
    };

    render() {
        let { currentSubmission } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <div className="standard-modal-content flex column">
                <div style={{ color: 'red' }}>
                    <b>Warning</b>, you are overriding a submission that contains validation errors
                </div>
                <br />
                {currentSubmission.incumbent_submission_ids.length > 0 ? this.getIncumbentDialogue() : null}
                {currentSubmission.blocking_submission_ids.length > 0 ? this.getBlockingDialogue() : null}
                <TextInput maxLength={300} name="reason" label="Reason For Override" onChange={this.handleChange} object={this.state.overrideInformation} />
                <div className="button-row">
                    <button className="green" onClick={this.handleAddOverrideReason}>
                        Save
                    </button>
                    <div className="spacer wide" />
                    <button className="red" onClick={this.handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}
