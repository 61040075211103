import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { AppConstants } from '@ventusrisk/jslib';
import DashboardContainer from './DashboardContainer';

export default function DashboardRoutes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/dashboard/:submissionId?" render={routeProps => <DashboardContainer match={routeProps.match} history={routeProps.history} />} />
                <Redirect from={'/'} to={AppConstants.DEFAULT_URL} />
            </Switch>
        </BrowserRouter>
    );
}
