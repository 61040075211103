import * as React from 'react';

import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import LiteTableContainer from '@ventusrisk/jslib/src/containers/LiteTableContainer';
import { LiteTableDataType, TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, blockPropTypes, VoidFn } from '../../../../ts-types/DataTypes';
import InfoBlock from '@ventusrisk/jslib/src/components/blocks/InfoBlock';
import { CLEARANCE_INFOBLOCKS } from '../../../../constants/InfoBlocks';
import { processColumnsForInfoBlocks } from '@ventusrisk/jslib/src/utils/tables/columns';

type propTypes = {
    onClose: (close: boolean) => void;
    tableGlossary: TableGlossaryType;
    currentSubmission: SubmissionType;
    CONSTANTS: Types.Constants;
    onGetDeltaRecords: VoidFn;
    tableData: LiteTableDataType;
    resetTable: VoidFn;
};

const INFO_BLOCKS: blockPropTypes[] = [CLEARANCE_INFOBLOCKS.debug_statistics];

export default class DebugInfoApp extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);
    }

    componentWillUnmount() {
        this.props.resetTable();
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    renderBlocks() {
        const { tableGlossary, CONSTANTS } = this.props;
        const blocks = [];
        const defs = processColumnsForInfoBlocks(CLEARANCE_INFOBLOCKS, tableGlossary, CONSTANTS);
        for (let block of INFO_BLOCKS) {
            blocks.push(
                <InfoBlock key={block.header} readonly={true} glossary={defs} rowObjectGetter="currentSubmission" parentProps={this.props} {...block} />
            );
        }
        return blocks;
    }

    render() {
        let { currentSubmission, tableData } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderBlocks()}
                <LiteTableContainer
                    onRowSelected={null}
                    hasActionPanel={false}
                    tableConfig={LITE_TABLES.DELTARECORDS}
                    containerClass="standard-modal-content wide tall"
                    tableData={tableData}
                    selectedRowId={0}
                />
            </React.Fragment>
        );
    }
}
