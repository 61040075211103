import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import MapScheduleView from '../containers/MapScheduleView.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';

type bodyPropTypes = {
    ENV: Types.Env;
    currentIndex: number;
    onClose: (close: boolean) => void;
};

class MapScheduleViewBody extends React.Component<bodyPropTypes> {
    render() {
        return <MapScheduleView {...this.props} />;
    }
}

const modalTypeObj = {
    Body: MapScheduleViewBody,
    className: 'schedule-view-modal map-height-unset',
};

registerType(ICUBED_MODAL_TYPES.MAP_SCHEDULE_VIEW, modalTypeObj);
export default modalTypeObj;
