import * as React from 'react';

import { ModalType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { DocumentDataType } from '@ventusrisk/jslib/src/ts-types/DataTypes';
import { InboxType, SubmissionType, UserType, VoidFn } from '../../ts-types/DataTypes';
import PreviewPanel from '../panels/PreviewPanel';
import InboxPanelContainer from '../panels/InboxPanel.container';
import PanelView from '../layout/PanelView';
import { getTaskSubtitles } from '../../utils/task-helpers';

import { CenteredModal } from '@ventusrisk/jslib/src/components/modals';
import VIKITasksContainer from '@ventusrisk/jslib/src/components/tasks/VikiTasksContainer';

import { PagedTableDataType, RowSelectedFnType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    currentInbox: InboxType;
    inboxes: InboxType[];
    currentSubmissionId: number;
    currentSubmission: SubmissionType;
    onRowSelected: RowSelectedFnType;
    onLoadSubmissionIfNeeded: (submissionId: number, inboxes: InboxType[], forceLoad?: boolean) => void;
    onTakeAction: (submissionId: number, newStatus: string, emailParams: Record<string, any>, sendEmailNotification: boolean) => void;
    onPopEmailForwardModal: (params: Record<string, any>) => void;
    onPopResponseModal: (data: Record<string, any>) => void;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    onLogOut: VoidFn;
    onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => void;
    onDownloadDocument: (document: DocumentDataType) => void;
    onDownloadAllSubmissionDocs: (submissionId: number) => void;
    onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => void;
    onRunSOVParser: (documentId: number) => void;

    onClassifySelectedText: (documentId: number, text: string) => void;
    openPanels: Record<string, any>;
    centeredModalData: ModalType;
    centeredModalType: string;
    CONSTANTS: Types.Constants;
    me: UserType;
    ENV: Types.Env;
    featureFlags: Record<string, boolean>;
    uiState: Record<string, any>;
    tableData: PagedTableDataType;

    onTogglePanel: (panel: string) => void;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    setUiState: (params: Record<string, any>, path: string) => void;

    onCloseCenteredModal: VoidFn;
};

class DashboardApp extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);

        this.props.onLoadSubmissionIfNeeded(this.props.currentSubmissionId, this.props.inboxes);
    }

    componentDidUpdate(prevProps: propTypes) {
        this.props.onLoadSubmissionIfNeeded(prevProps.currentSubmissionId, this.props.inboxes);
    }

    render() {
        // This resets PreviewPanel State when the submission changes
        // which is recommended vs trying to detect props changes and reset
        const { currentSubmission } = this.props;
        const previewKey = currentSubmission ? currentSubmission.id : 'none';

        return (
            <React.Fragment>
                <CenteredModal
                    CONSTANTS={this.props.CONSTANTS}
                    uiState={this.props.uiState}
                    ENV={this.props.ENV}
                    modalData={this.props.centeredModalData}
                    modalType={this.props.centeredModalType}
                    onClose={this.props.onCloseCenteredModal}
                />
                <VIKITasksContainer getSubtitles={getTaskSubtitles} />
                <PanelView
                    currentInbox={this.props.currentInbox}
                    currentSubmission={this.props.currentSubmission}
                    onSelectInbox={this.props.onSelectInbox}
                    onTogglePanel={this.props.onTogglePanel}
                    onLogOut={this.props.onLogOut}
                    openPanels={this.props.openPanels}
                    inboxes={this.props.inboxes}
                    ENV={this.props.ENV}
                    uiState={this.props.uiState}
                    featureFlags={this.props.featureFlags}
                >
                    <div className="main-content-center grid-layout gl-2 fixed grow">
                        <InboxPanelContainer
                            currentSubmission={this.props.currentSubmission}
                            currentSubmissionId={this.props.currentSubmissionId}
                            tableData={this.props.tableData}
                            currentInbox={this.props.currentInbox}
                            inboxes={this.props.inboxes}
                            uiState={this.props.uiState}
                            me={this.props.me}
                            CONSTANTS={this.props.CONSTANTS}
                            onRowSelected={this.props.onRowSelected}
                            onTakeAction={this.props.onTakeAction}
                            onPopResponseModal={this.props.onPopResponseModal}
                            onPopEmailForwardModal={this.props.onPopEmailForwardModal}
                            setUiState={this.props.setUiState}
                            onOpenCenteredModal={this.props.onOpenCenteredModal}
                        />
                        <PreviewPanel
                            key={previewKey}
                            CONSTANTS={this.props.CONSTANTS}
                            featureFlags={this.props.featureFlags}
                            currentSubmission={this.props.currentSubmission}
                            onDownloadDocument={this.props.onDownloadDocument}
                            onDownloadAllSubmissionDocs={this.props.onDownloadAllSubmissionDocs}
                            onRunSOVParser={this.props.onRunSOVParser}
                            onSaveDocumentData={this.props.onSaveDocumentData}
                            onOcrImage={this.props.onOcrImage}
                            onClassifySelectedText={this.props.onClassifySelectedText}
                        />
                    </div>
                </PanelView>
            </React.Fragment>
        );
    }
}

export default DashboardApp;
