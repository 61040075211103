import * as React from 'react';

import AccountSearch from '../containers/AccountSearch.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { DefaultHeaderWithProps } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

class AccountSearchBody extends React.Component<bodyPropTypes> {
    render() {
        return <AccountSearch {...this.props} />;
    }
}

const modalTypeObj = {
    Body: AccountSearchBody,
    Header: DefaultHeaderWithProps('Arch Account Search'),
    className: 'client-matching-modal fullscreen',
};

registerType(ICUBED_MODAL_TYPES.ACCOUNT_SEARCH, modalTypeObj);
export default modalTypeObj;
