import { ViewSettingsConfig } from '@ventusrisk/jslib/src/utils/tables/view-settings';

const accountsearchresults: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sela', 'aid', 'n'],
        columnList: ['as', 'st', 'c', 's', 'z', 'f'],
    },
};
const clientmatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'n'],
        columnList: ['ioi', 'st', 'c', 's', 'z', 'f'],
    },
};
const brokermatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'n'],
        columnList: ['e', 'c', 'o'],
    },
};
const duplicatesubmissions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['a', 'src'],
        columnList: ['i', 'sd', 'cn', 'cstreet', 'ccity', 'cstate', 'czipcode', 'b', 'bco', 'cfein', 'inc', 'exp', 'dtr', 'dti', 'bldc', 'tiv', 'uwbd', 'uwsd'],
    },
};
const borsubmissionmatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'i',
        sortDirection: 'ASC',
        fixedColumnList: ['a'],
        columnList: ['i', 'rno', 'agnt', 'proc', 'sd', 'cn', 'inc', 'exp', 'dtr', 'dti'],
    },
};
const deltarecords: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'hd',
        sortDirection: 'DESC',
        fixedColumnList: ['hd', 'had', 'ovn'],
        columnList: ['cf', 'co', 'cn', 'cr', 'hun'],
    },
};
const conflictingsubmissions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'an',
        sortDirection: 'DESC',
        fixedColumnList: ['an', 'n'],
        columnList: ['addr', 'addr1', 'sid', 'pas', 'c', 's', 'z', 'ct', 'effd', 'expd', 'div', 'sdiv'],
    },
};
const accountconflicts: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'an',
        sortDirection: 'DESC',
        fixedColumnList: ['sel', 'an', 'n'],
        columnList: ['expdt', 'addr', 'c', 's', 'z', 'ct', 'st'],
    },
};

export const LITE_TABLE_VIEW_SETTINGS = {
    accountsearchresults,
    clientmatches,
    brokermatches,
    duplicatesubmissions,
    borsubmissionmatches,
    deltarecords,
    conflictingsubmissions,
    accountconflicts,
};
