import * as React from 'react';
import { connect } from 'react-redux';

import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

import BuildingDistributionsApp from '../apps/BuildingDistributionsApp';
import { COMBINED_TABLE_GLOSSARY } from '../../../../constants/CombinedTableGlossary';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
};

type propTypes = {
    tableGlossary: TableGlossaryType;
    CONSTANTS: Types.Constants;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    return {
        CONSTANTS: state.global.CONSTANTS,
        tableGlossary: COMBINED_TABLE_GLOSSARY.submission,
        currentSubmission: state.submissionsLog.rowData[_ownProps.currentSubmission.id],
    };
};

const mapDispatchToProps = (_dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {};
};

class BuildingDistributions extends React.Component<propTypes> {
    render() {
        return <BuildingDistributionsApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BuildingDistributions);
export default connected;
