import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import OcrResultsApp from '../apps/OcrResultsApp';
import { selectNewClient } from '../../../../actions/SubmissionActions';
import { persistRowData_server } from '@ventusrisk/jslib/src/actions/TableActions';
import { processGridRequestWithId } from '@ventusrisk/jslib/src/utils/api/RequestUtils';
import { PAGED_TABLES } from '../../../../constants/PagedTableConfigs';
import { Types } from '../../../../ts-types/icubed-types';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    modalData: {
        submission_id: number;
        parsed_data: Record<string, any>;
        rowData: any;
    };
};

type propTypes = {
    onPersistRowData: (field: string, value: any) => void;
    onSelectNewClient: () => PromiseDispatchType;
    CONSTANTS: Types.Constants;
    modalData: {
        submission_id: number;
        parsed_data: Record<string, any>;
        rowData: any;
    };
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    return {
        uiState: state.uiState,
        CONSTANTS: state.global.CONSTANTS,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    let { submission_id, rowData } = ownProps.modalData;
    return {
        onSelectNewClient: () => {
            return dispatch(selectNewClient(submission_id, rowData[submission_id].custom_insured_id)).catch(e => {
                console.error(e);
            });
        },
        onPersistRowData: (currentTab: string, data: Record<string, any>) => {
            dispatch(persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(submission_id, { ...data }, rowData)));
        },
    };
};

class OcrResults extends React.Component<propTypes> {
    render() {
        return <OcrResultsApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(OcrResults);
export default connected;
