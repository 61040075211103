import { ApiStatusType, ValidationErrorType } from '../ts-types/DataTypes';
import { ARCH_DATA_CHECK_STATUSES, ICONS } from '../constants/Constants';
import GlobalStatus from '../components/misc/GlobalStatus';

type statusPanelProps = {
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
};

export const expandApiStatus = (statuses: ValidationErrorType[], CONSTANTS: Record<string, any>): ValidationErrorType[] => {
    return (statuses || []).map((status: ValidationErrorType) => {
        return { name: CONSTANTS.ARCH_CLEARANCE_WORKFLOW_STATUS[status.name], type: ICONS[status.type].icon };
    });
};

export const arrayOfStringsToValidationErrors = (a: string[], type = 'ERROR'): ValidationErrorType[] => {
    return (a || []).map(error => {
        return { type, name: error };
    });
};

export const getConfictsPanelHandle = (props: statusPanelProps) => {
    const status = props._arch_integration?.apiStatuses?.globalStatus;
    return (
        <>
            <div className="text">
                Archlink Status
                <GlobalStatus status={status} />
            </div>
        </>
    );
};

export const getTables = (props: statusPanelProps) => {
    const { accountStatuses, submissionStatuses } = props._arch_integration;
    return (
        <>
            <div className="grid-layout gl-2">
                <div className="insured-matching-status stndrd l">
                    <div className="toggle-header no-border-bottom no-max-width">
                        <label>Account Status :</label>
                    </div>

                    <div className="status-messages">
                        {accountStatuses.map((row: ValidationErrorType, idx: number) => {
                            return (
                                <div key={idx} className="errors-wrapper status modal">
                                    <div className="errors-content-item">
                                        <div className={`status-icons green`}>
                                            <span className="material-icons">{row.type}</span>
                                        </div>

                                        <div className="message">{row.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="insured-matching-status stndrd r">
                    <div className="toggle-header no-border-bottom no-max-width">
                        <label>Submission Status :</label>
                    </div>

                    <div className="status-messages">
                        {submissionStatuses.map((row: ValidationErrorType, idx: number) => {
                            return (
                                <div key={idx} className="errors-wrapper status modal">
                                    <div className="errors-content-item">
                                        <div className={`status-icons green`}>
                                            <span className="material-icons">{row.type}</span>
                                        </div>

                                        <div className="message">{row.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export const getConfictsPanel = (props: statusPanelProps) => {
    const { accountStatuses, submissionStatuses } = props._arch_integration;
    const dataCheckStatuses = ((props._arch_integration || {}).apiStatuses || {}).dataCheckStatuses || {};

    const archNotCalledYet =
        dataCheckStatuses &&
        accountStatuses &&
        submissionStatuses &&
        accountStatuses.length === 0 &&
        submissionStatuses.length === 0 &&
        dataCheckStatuses.name === ARCH_DATA_CHECK_STATUSES.CLIENT_VALIDATION_FAILED;

    const content = <div className={'width-100'}>{getTables(props)}</div>;
    const contentNotCall = (
        <div className="viki-loader validating">
            <div className="txt">Arch Api Calls Pending</div>
        </div>
    );

    return {
        handleContent: getConfictsPanelHandle(props),
        singleHeight: true,
        className: 'conflicts-status-panel',
        content: archNotCalledYet ? contentNotCall : content,
    };
};
