import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import api from '../services/apiSlice';
import listenerMiddleware from './listeners';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
        listenerMiddleware.middleware,
        ...getDefaultMiddleware({
            // TODO: Enable this
            serializableCheck: false,
            immutableCheck: false,
        }),
        api.middleware,
    ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
