import React, { useState } from 'react';
import classNames from 'classnames';

import { ArchClearanceApiStatus, SubmissionType, UnderwritingPlatformType, ValidationErrorType } from '../../ts-types/DataTypes';
import { arrayOfStringsToValidationErrors } from '../../utils/account-and-submission-conflct-utils';
import { ICONS, CLEARANCE_STATUS } from '../../constants/Constants';
import { ComboBoxInput } from '@ventusrisk/jslib/src/components/inputs';
import { useCurrentSubmission } from '../../hooks/submission';
import api, { useValidateQuery } from '../../services/apiSlice';
import { useParams } from 'react-router-dom';
import { usePusherEvent } from '@ventusrisk/jslib/src/hooks/pusher';
import { useAppDispatch } from '../../hooks/redux';

type buttonTypes = {
    currentSubmission: SubmissionType;
    name: string;
    pendingBorEnabled: boolean;
    onSaveFn: (fieldValueMap: any) => void;
    featureFlags: Record<string, boolean>;
    CONSTANTS: Record<string, any>;
};

const NO_VALIDATIONS_DISPLAY = 'There are no validation errors';

const filterListByValues = (theList, theValues) => {
    return theList.filter(function (e) {
        return ['value'].every(function (a) {
            return theValues.includes(e[a]);
        });
    });
};

const getValidationPanelErrors = (validations: any, currentSubmission: SubmissionType) => {
    let idErrors = validations?.ERROR?.id ? Object.assign([], validations.ERROR.id) : [];
    let archErrors = [];
    const reconciliationStatuses = [ArchClearanceApiStatus.RETRY_CLEAR, ArchClearanceApiStatus.RETRY];
    const currentSubmissionRequiresReconciliation = reconciliationStatuses.indexOf(currentSubmission.arch_clearance_api_status) >= 0;
    if (currentSubmissionRequiresReconciliation && currentSubmission.arch_clearance_api_error) {
        // TODO: sometimes these are JSONs, the backend should handle this
        //       see _arch_clearance_process_update_submission_status_response
        archErrors = [{ type: 'ERROR', name: currentSubmission.arch_clearance_api_error }];
    }
    const errorFields = { description_of_operations: 'Desc. of Operations' };
    for (const key in errorFields) {
        const error = validations?.ERROR?.[key];
        if (error) {
            idErrors.push(`${errorFields[key]}: ${error[0]}`);
        }
    }
    return arrayOfStringsToValidationErrors(idErrors, 'ERROR').concat(archErrors);
};

// TODO: Remove this once we figure out why UnderwritingPlatformToggle gets unmounted on submission data change
export const ValidationsRefetchWrapper = () => {
    const currentSubmission = useCurrentSubmission();
    useValidateQuery({ submissionId: currentSubmission?.id }, { skip: !currentSubmission?.id, refetchOnMountOrArgChange: true });
    return null;
};

const ValidationsPanelWrapper = ({ children, gridSpanWidth }: { children: React.ReactNode; gridSpanWidth: string }) => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    // TODO: Add refetchOnMountOrArgsChange. it doesn't work right now because the validations panel gets unmounted when currentSubmission is changed for some reason
    useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId });

    return (
        <div className={`insured-matching-status no-pad grid-${gridSpanWidth}-wide`}>
            <div className="status-messages">
                <div className="errors-wrapper status modal panel-area tw-flex tw-flex-col">{children}</div>
            </div>
        </div>
    );
};

const ValidationsPanel = ({ gridSpanWidth }: { gridSpanWidth: string }) => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    const dispatch = useAppDispatch();
    const currentSubmission = useCurrentSubmission();
    const { data, isFetching } = useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId });
    usePusherEvent(`SUBMISSIONS-${submissionId}`, 'validation-dirty', _message => {
        dispatch(api.util.invalidateTags([{ type: 'SubmissionValidation', id: +submissionId }]));
    });

    const validations = data?.validations;
    if (isFetching) {
        return (
            <ValidationsPanelWrapper gridSpanWidth={gridSpanWidth}>
                <div className="errors-content-item">
                    <div className="message">Validating</div>
                </div>
            </ValidationsPanelWrapper>
        );
    }
    const validationErrors = getValidationPanelErrors(validations, currentSubmission);
    if (validationErrors.length === 0) {
        return (
            <ValidationsPanelWrapper gridSpanWidth={gridSpanWidth}>
                <div className="errors-content-item">
                    <div className="status-icons blue">
                        <span className="material-icons">sentiment_satisfied_alt</span>
                    </div>

                    <div className="message">{NO_VALIDATIONS_DISPLAY}</div>
                </div>
            </ValidationsPanelWrapper>
        );
    }
    return (
        <ValidationsPanelWrapper gridSpanWidth={gridSpanWidth}>
            {validationErrors.map((error: ValidationErrorType) => {
                return (
                    <div key={error.name} className={`errors-content-item`}>
                        <div className={`status-icons ${ICONS[error.type].color}`}>
                            <span className="material-icons">{ICONS[error.type].icon}</span>
                        </div>

                        <div className="message">{formatErrors(error.name)}</div>
                    </div>
                );
            })}
        </ValidationsPanelWrapper>
    );
};

/**
 * Formats error messages and retuns them.
 * @param error The error message to be formatted
 * @returns The formatted error message
 */
function formatErrors(error: string): string {
    if (error.toLowerCase().includes('httpconnectionpool')) {
        return 'The Archlink API timed out. Please retry in a little while.';
    }
    return error;
}

const UnderwritingPlatformToggle = (props: buttonTypes) => {
    const { underwriting_platform, supported_uw_platforms, status } = props.currentSubmission;
    const retailEnabled = props.featureFlags.vmac_retail;

    const [platform, setPlatform] = useState(underwriting_platform);

    if (!underwriting_platform) {
        console.error('underwriting_platform is null, skipping');
        return null;
    }

    if (!supported_uw_platforms || supported_uw_platforms.length === 0) {
        console.error('underwriting_platform is null, skipping');
        return null;
    }

    const underwritingPlatformDisabled = status !== CLEARANCE_STATUS.UNPROCESSED;
    function handleToggle() {
        if (underwritingPlatformDisabled) {
            return;
        }
        const newPlatform = platform === UnderwritingPlatformType.POLICYWRITER ? UnderwritingPlatformType.VIKI : UnderwritingPlatformType.POLICYWRITER;
        setPlatform(newPlatform);
        props.onSaveFn({ [props.name]: newPlatform });
    }

    function handleClick(target) {
        if (underwritingPlatformDisabled) {
            return;
        }
        setPlatform(target);
        props.onSaveFn({ [props.name]: target });
    }

    const gridSpanWidth = props.pendingBorEnabled ? '4' : '3'; // VIKI-7007 pending_bor_enabled feature flag
    return (
        <React.Fragment>
            <ValidationsPanel gridSpanWidth={gridSpanWidth} />
            <div className="insured-matching-status no-pad grid-3-wide">
                <If condition={retailEnabled}>
                    <div className="header">
                        <div className="header-text">
                            Target Selected: <span className="blue-bright-txt">Policywriter</span>
                        </div>
                    </div>
                    <div className="info-rows">
                        <div className="input-label flex">Change Target</div>
                        <ComboBoxInput
                            object={props.currentSubmission}
                            disabled={underwritingPlatformDisabled}
                            label={null}
                            name="underwriting_platform"
                            onChange={handleToggle}
                            selectProps={{ choices: filterListByValues(props.CONSTANTS.UNDERWRITING_PLATFORM, supported_uw_platforms) }}
                        />
                    </div>
                </If>
                <If condition={!retailEnabled}>
                    <div className="status-messages no-scroll">
                        <div className="toggle-button-group button-group undefined vmac-toggle">
                            <button
                                className={classNames('toggle-default', {
                                    active: platform === UnderwritingPlatformType.VIKI,
                                })}
                                onClick={() => {
                                    handleClick(UnderwritingPlatformType.VIKI);
                                }}
                            >
                                Target
                                <br />
                                Viki
                            </button>

                            <button
                                className={classNames('toggle-default', {
                                    active: platform === UnderwritingPlatformType.POLICYWRITER,
                                })}
                                onClick={() => {
                                    handleClick(UnderwritingPlatformType.POLICYWRITER);
                                }}
                            >
                                Target
                                <br />
                                Policywriter
                            </button>
                        </div>
                    </div>
                </If>
            </div>
        </React.Fragment>
    );
};

export default UnderwritingPlatformToggle;
