import * as types from '../constants/ActionTypes';

import lockr from 'lockr';

const INBOX_ID_KEY = 'INBOX_ID';

let initialState = {
    list: [],
    inboxId: lockr.get(INBOX_ID_KEY) || null,
};

function inboxReducer(state = initialState, action) {
    switch (action.type) {
        case types.RECEIVE_INBOXES: {
            let { inboxes } = action;
            let { inboxId } = state;

            if (!inboxId) {
                inboxId = inboxes[0].id;
            }

            return Object.assign({}, state, {
                list: action.inboxes,
                inboxId,
            });
        }

        case types.SET_CURRENT_INBOX: {
            let { inboxId } = action;
            lockr.set(INBOX_ID_KEY, inboxId);
            return Object.assign({}, state, {
                inboxId,
            });
        }

        default:
            return state;
    }
}

export const RELATED_ACTION_TYPES = [types.SET_CURRENT_INBOX, types.RECEIVE_INBOXES];

export default inboxReducer;
