import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import DuplicatesTableApp from '../apps/DuplicatesTableApp';
import { getSubmissionDuplicates } from '../../../../actions/SubmissionActions';
import { Types } from '../../../../ts-types/icubed-types';
import { VoidFn } from '../../../../ts-types/DataTypes';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { resetTable } from '@ventusrisk/jslib/src/actions/TableActions';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

type propTypes = {
    onGetSubmissionDuplicates: VoidFn;
    onClickDuplicateInTable: (sid: number) => void;
    onDismissSubmissionDuplicates: VoidFn;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    return {
        tableData: state.duplicateSubmissions,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    let { currentSubmission } = ownProps;
    return {
        onGetSubmissionDuplicates: () => {
            return dispatch(getSubmissionDuplicates(currentSubmission.id, LITE_TABLES.DUPLICATESUBMISSIONS));
        },
        onDismissSubmissionDuplicates: () => dispatch(resetTable(LITE_TABLES.DUPLICATESUBMISSIONS)),
    };
};

class DuplicatesTable extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);

        this.props.onGetSubmissionDuplicates();
    }

    componentWillUnmount() {
        this.props.onDismissSubmissionDuplicates();
    }

    render() {
        return <DuplicatesTableApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(DuplicatesTable);
export default connected;
