import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import OverrideBusinessRules from '../containers/OverrideBusinessRules.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { SubmissionType } from '../../../../ts-types/DataTypes';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    action: string;
    tableData: LiteTableDataType;
    currentSubmission: SubmissionType;
};

class OverrideBusinessRulesBody extends React.Component<bodyPropTypes> {
    render() {
        return <OverrideBusinessRules {...this.props} />;
    }
}

const modalTypeObj = {
    Body: OverrideBusinessRulesBody,
    Header: DefaultHeaderStatic('PROVIDE A REASON FOR THE OVERRIDE'),
    className: 'override-business-modal',
};

registerType(ICUBED_MODAL_TYPES.OVERRIDE_BUSINESS_RULES, modalTypeObj);
export default modalTypeObj;
