import * as React from 'react';

import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { SubmissionType, UserType } from '../../../../ts-types/DataTypes';
import BorNotification from '../containers/BorNotification.container';
import { Types } from '../../../../ts-types/icubed-types';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    oldestSubmission: SubmissionType;
    incumbentSubmission: SubmissionType;
    borSubmissions: string[];
    me: UserType;
    ENV: Types.Env;
    featureFlags: Record<string, string>;
    modalData: {
        action: string;
        replyToEmail: string;
        currentSubmissionId: number;
    };
};

class BorNotificationBody extends React.Component<bodyPropTypes> {
    render() {
        return <BorNotification {...this.props} />;
    }
}

type headerPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    onGetDeltaRecords: () => void;
};

class BorNotificationHeader extends React.Component<headerPropTypes> {
    render() {
        return DefaultHeaderStatic(`BOR Notification to Policy Services`);
    }
}

const modalTypeObj = {
    Body: BorNotificationBody,
    Header: BorNotificationHeader,
    className: 'debug-info-modal width-40',
};

registerType(ICUBED_MODAL_TYPES.BOR_NOTIFICATION, modalTypeObj);
export default modalTypeObj;
