import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import AddAccountBroker from '../containers/AddAccountBroker.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    currentSubmissionId: number;
    onClose: (close: boolean) => void;
};

class AddAccountBrokerBody extends React.Component<bodyPropTypes> {
    render() {
        return <AddAccountBroker {...this.props} />;
    }
}

const modalTypeObj = {
    Body: AddAccountBrokerBody,
    Header: DefaultHeaderStatic('Add Account Broker'),
    className: 'add-note-modal width-75',
};

registerType(ICUBED_MODAL_TYPES.ADD_ACCOUNT_BROKER, modalTypeObj);
export default modalTypeObj;
