import _ from 'lodash';

const documentSortMap = {
    SUBMISSION_EMAIL: 1,
    ACORD: 2,
    SOV: 3,
};

export const applyCustomDocumentOrdering = (docs: any[]) => {
    return _.sortBy(docs, d => documentSortMap[d.type]);
};
