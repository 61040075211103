import { useParams } from 'react-router-dom';
import { RootState } from '../store';
import { SubmissionType } from '../ts-types/DataTypes';

import { useAppSelector } from './redux';

export const selectSubmissionById = (state: RootState, id: number) => state.submissionsLog.rowData?.[id];

export const useCurrentSubmission = () => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    return useAppSelector(state => selectSubmissionById(state, +submissionId)) as SubmissionType;
};
