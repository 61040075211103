import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import BorSubmissionMatching from '../containers/BorSubmissionMatching.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    tableData: LiteTableDataType;
    currentSubmission: SubmissionType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    sendEmailNotification: boolean;
};

class BorSubmissionsBody extends React.Component<bodyPropTypes> {
    render() {
        return <BorSubmissionMatching {...this.props} />;
    }
}

type headerPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
};

class BORSubmissionSearchHeader extends React.Component<headerPropTypes> {
    render() {
        let { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Mark Pending BOR - Account ID: A${currentSubmission.arch_account_arch_id}`);
    }
}

const modalTypeObj = {
    Body: BorSubmissionsBody,
    Header: BORSubmissionSearchHeader,
    className: 'duplicate-submission-modal',
};

registerType(ICUBED_MODAL_TYPES.BOR_SUBMISSION_MATCHING, modalTypeObj);
export default modalTypeObj;
