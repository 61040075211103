import * as React from 'react';

import isEqual from 'react-fast-compare';
import { Types } from '../../../../ts-types/icubed-types';
import LiteTableContainer from '@ventusrisk/jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { VoidFn, SubmissionType, ApiStatusType, InboxType, PromiseDispatchType, ValidationErrorType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';
import { VMAC_NO_ACCOUNT_CONFLICTS_EVENT, VMAC_ACCOUNT_MATCHING_SELECTION_EVENT } from '../../../../constants/AnalyticsConstants';
import { ARCH_API_MESSAGES, ARCH_DATA_CHECK_STATUSES, ARCH_STATUSES } from '../../../../constants/Constants';
import { arrayOfStringsToValidationErrors } from '../../../../utils/account-and-submission-conflct-utils';
import ErrorsAndWarningsPanel from '../../../panels/ErrorsAndWarningsPanel';
import { TextInput } from '@ventusrisk/jslib/src/components/inputs';
import { CENTERED_MODAL_TYPES } from '@ventusrisk/jslib/src/constants/Constants';

type propTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    archTableData: LiteTableDataType;
    resetTable: VoidFn;
    onGetAccountConflicts: () => PromiseDispatchType;
    onRetrySubmissionWithArch: (sid: number) => PromiseDispatchType;
    onSelectAccountId: (overrideId: string) => PromiseDispatchType;
    onResolveAccountConflict: (accountId: string) => PromiseDispatchType;
    onOpenMessageModal: (params: Record<string, any>, modalType: string) => void;
    onMarkRowAsSelected: (rowId: number) => void;
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
    CONSTANTS: Types.Constants;
    onGetApiStatuses: () => void;
    currentInbox: InboxType;
};

type stateTypes = {
    errors?: string[];
    showProgress: boolean;
    didRetryArch: boolean;
    showProgressText: boolean;
    archOverrideAccountId?: string;
    conflictResolutionInProgress: boolean;
};

const POOL_ACCOUNT_CONFLICTS_AFTER_SECONDS = 15;

const COMMON_BLOCKING_ERRORS = ['is not a valid Underwriter'];
const ARCH_BLOCKING_ERRORS = [
    ...COMMON_BLOCKING_ERRORS,
    'A valid ProducerId is required',
    'Unable to connect to conflict service',
    'Account number in the request and account number in the submission are mismatched.',
];
const IGNORABLE_BLOCKING_ERRORS = ['Empty insured fields. Not calling Arch Clearance API'];
const RETRY_ARCH_BLOCKING_ERRORS = [...COMMON_BLOCKING_ERRORS];

export default class ClientMatchingApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    initialSearchValue = '';
    didPerformInitialArchRequest = false;
    lastSelectedArchAccount: number;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            errors: null,
            didRetryArch: false,
            showProgress: false,
            showProgressText: false,
            archOverrideAccountId: null,
            conflictResolutionInProgress: false,
        };
    }

    _logEvent(onPageLoad: boolean) {
        const { currentSubmission } = this.props;
        this.context.analytics.track(VMAC_NO_ACCOUNT_CONFLICTS_EVENT, {
            onPageLoad,
            submission_id: currentSubmission.id,
            archData: this.props._arch_integration,
            archStatus: currentSubmission.arch_clearance_api_status,
        });
    }

    componentDidMount() {
        const { currentSubmission } = this.props;
        if (this.state.conflictResolutionInProgress === false) {
            this.props.onGetAccountConflicts().then(data => {
                if (data.length === 0 && currentSubmission) this._logEvent(true);
            });
        }
    }

    componentDidUpdate(prevProps: propTypes) {
        const { currentSubmission } = this.props;
        if (
            prevProps.currentSubmission &&
            currentSubmission &&
            currentSubmission.is_arch_integration_enabled &&
            prevProps.currentSubmission.arch_clearance_api_status !== currentSubmission.arch_clearance_api_status &&
            currentSubmission.arch_clearance_api_status !== ARCH_STATUSES.PROCESSING &&
            currentSubmission.arch_clearance_api_status !== ARCH_STATUSES.PENDING_PROCESSING &&
            this.state.conflictResolutionInProgress === false
        ) {
            console.info('Arch status changed, pulling new conflicts');
            this.setState({ showProgress: true });
            this.props.onGetAccountConflicts().then(data => {
                this.lastSelectedArchAccount = null;
                let showProgress = false;
                if (data.length === 0 && currentSubmission) this._logEvent(false);
                if (
                    data.length === 0 &&
                    this.state.conflictResolutionInProgress === false &&
                    currentSubmission.arch_clearance_api_status !== ARCH_STATUSES.ERROR
                ) {
                    showProgress = true;
                    setTimeout(() => {
                        console.info('re-polling account conflicts');
                        this.props.onGetAccountConflicts().then(data => {
                            this.setState({ showProgressText: false, showProgress: false });
                            if (data.length === 0 && currentSubmission) this._logEvent(false);
                        });
                    }, POOL_ACCOUNT_CONFLICTS_AFTER_SECONDS * 1000);
                }
                this.setState({ showProgress });
            });
            this.props.onGetApiStatuses();
        }
    }

    shouldComponentUpdate(nextProps: propTypes, nextState: Record<string, any>) {
        const shouldUpdate = !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
        return shouldUpdate;
    }

    getCommonBlockingErrors = (): string[] => {
        return [...COMMON_BLOCKING_ERRORS, ...Object.values(this.props.CONSTANTS.ARCH_CLEARANCE_VALIDATION_ERRORS)];
    };

    getArchBlockingEerrors = (): string[] => {
        return [...ARCH_BLOCKING_ERRORS, ...this.getCommonBlockingErrors()].filter(err => IGNORABLE_BLOCKING_ERRORS.indexOf(err) < 0);
    };

    getRetryArchLinkBlockingErrors = (): string[] => {
        return [...this.getCommonBlockingErrors(), ...RETRY_ARCH_BLOCKING_ERRORS];
    };

    handleCancel = () => {
        this.props.onClose(true);
    };

    onSelectOverride = (rowId: number, newRowIndex: number, newRowData: any) => {
        const { onMarkRowAsSelected, onResolveAccountConflict, currentSubmission, currentInbox } = this.props;

        this.context.analytics.track(VMAC_ACCOUNT_MATCHING_SELECTION_EVENT, {
            inbox_id: currentInbox.id,
            account_name: newRowData.name,
            submission_id: currentSubmission.id,
            account_number: newRowData.accountNumber,
        });

        this.setState({ showProgress: true, showProgressText: true, conflictResolutionInProgress: true }, () => {
            onMarkRowAsSelected(rowId);
            onResolveAccountConflict(newRowData.accountNumber).then(() => {
                this.props.onClose(true);
            });
        });
    };

    getVikiValidationMessages(): ValidationErrorType[] {
        const { currentSubmission } = this.props;
        const errors = currentSubmission && currentSubmission._validations && currentSubmission._validations.ERROR && currentSubmission._validations.ERROR.id;
        return arrayOfStringsToValidationErrors(errors, ARCH_STATUSES.ERROR);
    }

    getArchValidationMessages(): ValidationErrorType[] {
        const apiStatuses = this.props._arch_integration.apiStatuses;
        if (!apiStatuses) {
            return [];
        }
        if (apiStatuses.globalStatus === ARCH_STATUSES.ERROR) {
            return [];
        }
        const { accountStatuses, submissionStatuses } = this.props._arch_integration;
        const dataCheckStatuses = ((this.props._arch_integration || {}).apiStatuses || {}).dataCheckStatuses || {};
        const archNotCalledYet =
            dataCheckStatuses &&
            accountStatuses &&
            submissionStatuses &&
            accountStatuses.length === 0 &&
            submissionStatuses.length === 0 &&
            dataCheckStatuses.name === ARCH_DATA_CHECK_STATUSES.CLIENT_VALIDATION_FAILED;

        return archNotCalledYet
            ? arrayOfStringsToValidationErrors(['Arch Api Calls Pending'], ARCH_STATUSES.WARN)
            : arrayOfStringsToValidationErrors([ARCH_API_MESSAGES[apiStatuses.globalStatus]], apiStatuses.globalStatus);
    }

    getAllValidationMessages(): ValidationErrorType[] {
        const validationMessages = this.getVikiValidationMessages().concat(this.getArchValidationMessages());
        // sort s.t. validations that block calls to AL are first
        validationMessages.sort((a, b) => {
            const blockingErrors = this.getArchBlockingEerrors();
            const aContained = blockingErrors.filter(e => a.name.indexOf(e) >= 0).length > 0;
            const bContained = blockingErrors.filter(e => b.name.indexOf(e) >= 0).length > 0;
            return aContained && bContained ? 0 : aContained && !bContained ? -1 : 1;
        });
        return validationMessages;
    }

    isSystemInProgress() {
        //modal status is a bit different than global status because we need to take into account the search state.
        const archStatus = this.props._arch_integration?.apiStatuses?.globalStatus;
        return archStatus === ARCH_STATUSES.IN_PROGRESS || this.state.showProgress;
    }

    handleAccountOverride = (key: string, value: any) => {
        this.setState({ archOverrideAccountId: value, errors: null });
    };

    handleSetOverride = () => {
        this.props.onSelectAccountId(this.state.archOverrideAccountId);
        this.setState({ archOverrideAccountId: null });
    };

    getGlobalStatusError = () => {
        const { currentSubmission } = this.props;
        return (
            currentSubmission.is_arch_integration_enabled === true &&
            this.getAllValidationMessages().length === 0 &&
            currentSubmission.apiStatuses?.globalStatus === 'ERROR' &&
            currentSubmission.apiStatuses?.submission.filter(s => s.name === 'SUBMISSION_CONFLICT').length === 0
        );
    };

    handleRetryArch = () => {
        this.setState({ didRetryArch: true });
        this.props.onRetrySubmissionWithArch(this.props.currentSubmission.id);
    };

    renderGuidancePanel() {
        const { currentSubmission } = this.props;
        const { errors, didRetryArch } = this.state;
        const showProgress = this.isSystemInProgress();

        if (!currentSubmission.is_arch_integration_enabled) {
            return null;
        }

        const validationItems = this.getAllValidationMessages();
        const isSynced = validationItems.length === 1 && validationItems[0]['type'] === ARCH_STATUSES.SUCCESS;
        const globalStatusError = this.getGlobalStatusError();
        if (globalStatusError) {
            validationItems.push({
                type: 'ERROR',
                name: 'An error occurred communicating with ArchLink, please trigger a call to ArchLink or wait for reconciliation.',
            });
        }

        const retryBlockingStatuses = ['PENDING_SUBMISSION_CLEAR'];
        const retryArchDisabled =
            didRetryArch ||
            (!globalStatusError &&
                (retryBlockingStatuses.indexOf(currentSubmission.arch_clearance_api_status) >= 0 ||
                    this.filterValidationErrors(this.getRetryArchLinkBlockingErrors()).length > 0));

        return (
            <div className="insured-matching-status padding-standard">
                <div className="toggle-header no-border-bottom">
                    <label>Guidance :</label>
                    <If condition={showProgress}>
                        <div className="progress-indicator inline number" />
                    </If>
                    <If condition={!showProgress}>
                        <If condition={(!isSynced && validationItems.length > 0) || globalStatusError}>
                            <span className="material-icons red">report</span>
                        </If>
                        <If condition={!globalStatusError && (isSynced || validationItems.length === 0)}>
                            <span className="material-icons green">check_circle</span>
                        </If>
                    </If>

                    <div className="flex f-row retry-arch">
                        <button disabled={retryArchDisabled} onClick={this.handleRetryArch} className="grey-dark">
                            Retry ArchLink
                        </button>
                    </div>

                    <div className="flex f-row override-arch-id">
                        <label>Override Arch Account ID :</label>
                        <TextInput
                            errors={errors}
                            onEveryChange={this.handleAccountOverride}
                            object={this.state}
                            name="archOverrideAccountId"
                            noWrapper={true}
                            disabled={currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                        />
                        <button
                            disabled={!this.state.archOverrideAccountId || currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                            onClick={this.handleSetOverride}
                            className="grey-dark"
                        >
                            Override
                        </button>
                    </div>
                </div>

                <div className="status-messages">
                    <div className="errors-wrapper status modal">
                        <ErrorsAndWarningsPanel items={validationItems} currentSubmission={this.props.currentSubmission} />
                    </div>
                </div>
            </div>
        );
    }

    filterValidationErrors = (errorList: string[]): ValidationErrorType[] => {
        return this.getAllValidationMessages().filter(v => errorList.filter(e => v.name.indexOf(e) >= 0).length > 0);
    };

    handleRowSelect = (rowId: number, newRowIndex: number, newRowData: any) => {
        const { archTableData, onOpenMessageModal } = this.props;
        if (archTableData?.rowData[rowId]?.accountNumber === 'NEW') {
            onOpenMessageModal(
                {
                    title: 'Resolving the conflict with the New account will create a brand new cleared account and associate this submission with it',
                    modalData: {
                        confirmLabelTitle: 'Proceed',
                        dismissButtonTitle: 'Cancel',
                        onOk: () => {
                            this.onSelectOverride(rowId, newRowIndex, newRowData);
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            );
        } else {
            this.onSelectOverride(rowId, newRowIndex, newRowData);
        }
    };

    render() {
        const { currentSubmission, archTableData } = this.props;
        if (!currentSubmission) {
            return null;
        }

        const isSystemInProgress = this.isSystemInProgress();
        const className = 'gl-1-force';

        let showGuidelinesOverlay = false;
        let showArchGuidelinesOverlay = false;
        const globalArchAPIError = this.getGlobalStatusError();
        let overlayText = null;

        const optionalProps: Record<string, any> = {};

        const blockingValidations = this.filterValidationErrors(this.getArchBlockingEerrors());
        showGuidelinesOverlay = blockingValidations.length > 0 || globalArchAPIError;
        if (showGuidelinesOverlay) {
            overlayText = (
                <div>
                    <div className="status-icons red">
                        <span className="material-icons red">report</span>
                    </div>{' '}
                    Unable to call ArchLink: {blockingValidations.length > 0 ? blockingValidations[0].name : 'Please refer to the guidelines above.'}
                </div>
            );
        }
        //  else if (currentSubmission.arch_clearance_api_last_call) {
        //     optionalProps.progressText = `${CONSTANTS.ARCH_CLEARANCE_API_CALLS[currentSubmission.arch_clearance_api_last_call]}...`;
        // }

        if (currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS) {
            showGuidelinesOverlay = true;
            overlayText = (
                <div>
                    <div className="status-icons red">
                        <span className="material-icons orange">report</span>
                    </div>{' '}
                    Warning: Cannot edit because submission has been cleared to ArchLink.
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="padding-standard">
                    <div className="modal-grid">
                        <div className="flex-table-row row clmn-headers brd-lft">
                            <div className="width-100">
                                <label>Insured Name Parsed From Email</label>

                                <span>{this.props.currentSubmission.arch_account_name_as_parsed}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider-line"></div>

                <div className="grid-layout gl-1 margin-top">{this.renderGuidancePanel()}</div>
                <div className={`grid-layout h-100 ${className} no-gap`}>
                    <If condition={currentSubmission.is_arch_integration_enabled}>
                        <div>
                            <div className="clmn-headers padding-standard padding-bottom-none">
                                <label>ARCH DATA</label>
                            </div>

                            <LiteTableContainer
                                {...optionalProps}
                                disableTable={isSystemInProgress}
                                onRowSelected={null}
                                hasActionPanel={false}
                                tableConfig={LITE_TABLES.ACCOUNTCONFLICTS}
                                containerClass="standard-modal-content width-100 no-padding-top h-adjust"
                                tableData={archTableData}
                                selectedRowId={2}
                                showProgressText={this.state.showProgressText}
                                disableTableWithoutProgress={showGuidelinesOverlay || showArchGuidelinesOverlay}
                                overlayText={overlayText}
                                actionHelperFns={{
                                    onSelectNewMatch: this.handleRowSelect,
                                }}
                            />
                        </div>
                    </If>
                </div>

                {/* <div className="divider-line"></div>

                 */}
            </React.Fragment>
        );
    }
}
