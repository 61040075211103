import * as React from 'react';
import { connect } from 'react-redux';
import { applyParamsToPath, getQueryStringParam, setQueryParam } from '@ventusrisk/jslib/src/utils/js-helpers';
import { match } from 'react-router-dom';
import { History } from 'history';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { getAccountConflicts } from '../../actions/ArchActions';

import { VikiTableCellContext } from '@ventusrisk/jslib/src/utils/context';
import { downloadDocument } from '@ventusrisk/jslib/src/actions/CommonActions';
import {
    takeAction,
    downloadAllSubmissionDocs,
    saveDocumentData,
    getSubmissionDataFromServer,
    removeSubmissionIsDirtyFlag,
    runSOVParser,
    overrideAutoAction,
} from '../../actions/SubmissionActions';
import { closeCenteredModal, openCenteredModal } from '@ventusrisk/jslib/src/actions/GlobalActions';

import { popEmailResponseModal, popEmailForwardModal } from '../../actions/EmailActions';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { ICUBED_MODAL_TYPES, DEBUG_INFO_MODAL_WIDTH } from '../../constants/Modals';
import { HOT_KEYS } from '../../constants/HotKeys';
import { VMAC_ROW_SELECTED } from '../../constants/AnalyticsConstants';

import DashboardApp from './DashboardApp';
import { getInboxes_server, setCurrentInbox } from '../../actions/InboxActions';
import { selectTableRow } from '@ventusrisk/jslib/src/actions/TableActions';

import { getGlossarys } from '../../actions/DebugActions';
import { getNotes_server } from '@ventusrisk/jslib/src/actions/NoteActions';
import { logout } from '@ventusrisk/jslib/src/actions/UserActions';
import { ocrImage_server, classifySelectedText_server } from '../../actions/MiscActions';

import { PagedTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { ModalType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { DocumentDataType } from '@ventusrisk/jslib/src/ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import { AppContext } from '@ventusrisk/jslib/src/utils/context';
import { getCurrentInbox } from '../../utils/inbox-helpers';
import { updeepUi } from '@ventusrisk/jslib/src/actions/CommonActions';
import { flip } from '@ventusrisk/jslib/src/utils/updeep-helpers';
import { SubmissionType, InboxType, VoidFn, UserType } from '../../ts-types/DataTypes';
import { showConfirmThen } from '@ventusrisk/jslib/src/utils/ui-helpers';
import { RootState } from '../../store';

type ownPropTypes = {
    match: match<{ submissionId?: string }>;
    history: History;
};

type propTypes = {
    onPopEmailForwardModal: (params: Record<string, any>) => void;
    onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => void;
    onCloseCenteredModal: VoidFn;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => void;

    onClassifySelectedText: (documentId: number, text: string) => void;
    onLogOut: VoidFn;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    featureFlags: Record<string, any>;
    onTogglePanel: (panel: string) => void;
    setUiState: (params: Record<string, any>, path: string) => void;
    currentSubmission: SubmissionType;
    currentSubmissionId: number;
    inboxes: InboxType[];
    currentInbox: InboxType;
    openPanels: Record<string, any>;
    onLoadSubmissionIfNeeded: (submissionId: number, inboxes: InboxType[], forceLoad?: boolean) => void;
    onRemoveSubmissionIsDirtyFlag: (submissionData: Record<string, any>) => void;
    onDownloadDocument: (document: DocumentDataType) => void;
    onDownloadAllSubmissionDocs: (submissionId: number) => void;
    onTakeAction: (submissionId: number, newStatus: string, emailParams: Record<string, any>, sendEmailNotification: boolean) => void;
    onPopResponseModal: (data: Record<string, any>) => void;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    onGetInboxes: VoidFn;
    onGetGlossarys: (tables: string[]) => void;
    onOverrideAutoAction: (sid: number) => void;
    onSelectRow: (rowId: number) => void;
    onUpdatePanelStateForArchIntegration: (archEnabled: boolean) => void;
    onRunSOVParser: (documentId: number) => void;
    centeredModalData: ModalType;
    centeredModalType: string;
    me: UserType;
    uiState: Record<string, any>;
    tableData: PagedTableDataType;
} & ownPropTypes;

const FETCHED_SUBMISSIONS = {};

const INBOX_QUERY_KEY = 'inbox';

const mapStateToProps = (state: RootState, ownProps: ownPropTypes) => {
    let currentSubmissionId = ownProps.match.params.submissionId ? Number(ownProps.match.params.submissionId) : null;
    let currentSubmission = state.submissionsLog.rowData[currentSubmissionId] || {
        id: null,
    };
    const inboxParam = Number(getQueryStringParam(INBOX_QUERY_KEY));
    let currentInbox = getCurrentInbox(state, inboxParam);
    return {
        tableData: state.submissionsLog,
        tableConfig: PAGED_TABLES.SUBMISSIONS,
        me: state.global.me,
        uiState: state.uiState,
        currentSubmission,
        currentSubmissionId,
        inboxes: state.inboxes.list,
        centeredModalData: state.global.centeredModalData,
        centeredModalType: state.global.centeredModalType,
        openPanels: state.uiState.openPanels,
        currentInbox,
        CONSTANTS: state.global.CONSTANTS,
        ENV: state.global.ENV,
        featureFlags: state.global.featureFlags,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, _ownProps: ownPropTypes) => {
    const onLoadExtraSubmissionData = (submissionId: number) => {
        dispatch(getSubmissionDataFromServer(submissionId, ['arch_account.*', 'documents.*']));
    };

    const onGetNotes = (submissionId: number) => {
        return dispatch(getNotes_server({ id: submissionId }));
    };

    const onSelectRow = (rowId: number) => {
        return dispatch(selectTableRow(PAGED_TABLES.SUBMISSIONS, rowId));
    };

    const onGetAccountConflicts = (submissionId: number) => {
        return dispatch(getAccountConflicts(submissionId));
    };

    const onRemoveSubmissionIsDirtyFlag = (submissionData: Record<string, any>) => {
        dispatch(removeSubmissionIsDirtyFlag(submissionData));
        if (FETCHED_SUBMISSIONS[submissionData.id]) {
            delete FETCHED_SUBMISSIONS[submissionData.id];
        }
    };

    return {
        onGetInboxes: () => {
            dispatch(getInboxes_server());
        },
        onLoadSubmissionIfNeeded: (submissionId: number, inboxes, forceLoad) => {
            // wait for inboxes before loading submission data
            if (submissionId && inboxes.length && (forceLoad === true || !FETCHED_SUBMISSIONS[submissionId])) {
                FETCHED_SUBMISSIONS[submissionId] = true;
                onLoadExtraSubmissionData(submissionId);
                onGetNotes(submissionId);
                onGetAccountConflicts(submissionId);
            }
        },
        onRemoveSubmissionIsDirtyFlag: (submissionData: Record<string, any>) => {
            onRemoveSubmissionIsDirtyFlag(submissionData);
        },
        onTakeAction: (submissionId: number, newStatus: string, emailParams: Record<string, any>, sendEmailNotification: boolean) => {
            return dispatch(takeAction(submissionId, newStatus, emailParams, [], sendEmailNotification));
        },
        onDownloadDocument: (document: DocumentDataType) => {
            return dispatch(downloadDocument(document));
        },
        onRunSOVParser: (documentId: number) => {
            return dispatch(runSOVParser(documentId));
        },
        onDownloadAllSubmissionDocs: (submissionId: number) => {
            return dispatch(downloadAllSubmissionDocs(submissionId));
        },
        onSelectInbox: (inboxId: number, archEnabled: boolean) => {
            setQueryParam(INBOX_QUERY_KEY, String(inboxId)); // TODO: use-query-params
            dispatch(updeepUi({ BOTTOM_submissiondata: { index: archEnabled ? 0 : 1 } }, 'openPanels'));
            dispatch(setCurrentInbox(inboxId));
        },
        onPopResponseModal: (data: Record<string, any>) => {
            dispatch(popEmailResponseModal(data));
        },
        onPopEmailForwardModal: (data: Record<string, any>) => {
            dispatch(popEmailForwardModal(data));
        },
        onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => {
            return dispatch(saveDocumentData(submissionId, documentId, data));
        },
        onLogOut: () => {
            return dispatch(logout());
        },
        onOpenCenteredModal: (modalData: Record<string, any>, centerModalType: string) => {
            return dispatch(openCenteredModal(modalData, centerModalType));
        },
        onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => {
            return dispatch(ocrImage_server(documentId, page, canvasDims));
        },
        onGetGlossarys: (tables: string[]) => {
            return dispatch(getGlossarys(tables));
        },
        onClassifySelectedText: (documentId: number, text: string) => {
            return dispatch(classifySelectedText_server(documentId, text));
        },
        onUpdatePanelStateForArchIntegration: (archEnabled: boolean) => {
            return dispatch(updeepUi({ BOTTOM_submissiondata: { index: archEnabled ? 1 : 0 } }, 'openPanels'));
        },
        onOverrideAutoAction: (submissionId: number) => {
            return dispatch(overrideAutoAction(submissionId));
        },
        onSelectRow,
        setUiState: (data, componentPath) => {
            dispatch(updeepUi(data, componentPath));
        },
        onCloseCenteredModal: () => {
            dispatch(closeCenteredModal());
        },
        onTogglePanel: (panel, isOpen) => {
            dispatch(updeepUi({ [panel]: typeof isOpen === 'boolean' ? isOpen : flip }, 'openPanels'));
        },
        onSetSendEmailNotification: sendEmailNotification => {
            dispatch(updeepUi(sendEmailNotification, 'sendEmailNotification'));
        },
    };
};

class DashboardContainer extends React.Component<propTypes> {
    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);

        this.props.onGetInboxes();
        this.props.onGetGlossarys(['insured', 'submission', 'archaccount']);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onWindowKeyDown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onWindowKeyDown, false);
    }

    componentDidUpdate(prevProps: propTypes) {
        const prevSubmission = prevProps.currentSubmission;
        const { currentSubmission, inboxes } = this.props;

        if (!prevSubmission._is_dirty && currentSubmission._is_dirty) {
            this.props.onLoadSubmissionIfNeeded(currentSubmission.id, inboxes, true);
        }

        if (prevSubmission.is_arch_integration_enabled !== currentSubmission.is_arch_integration_enabled) {
            this.props.onUpdatePanelStateForArchIntegration(currentSubmission.is_arch_integration_enabled);
        }
        this.markDirtySubmissionsFetchable();
    }

    getContextValues() {
        const context: Record<string, any> = {};

        context['cellBadgeOnClickEvents'] = {
            'is-arch-submission-conflict-error': this.handleShowConflictSubmissionDebugPanel,
            'handle-override-auto-action': this.handleOverrideAutoAction,
        };

        return context;
    }

    markDirtySubmissionsFetchable = () => {
        // if an already-fetched submission is dirty then remove the dirty flag and remove from FETCHED_SUBMISSIONS
        const { tableData, onRemoveSubmissionIsDirtyFlag } = this.props;
        if (tableData && tableData.rowData) {
            for (const submissionId in FETCHED_SUBMISSIONS) {
                if (tableData.rowData[submissionId] && tableData.rowData[submissionId]._is_dirty) {
                    onRemoveSubmissionIsDirtyFlag(tableData.rowData[submissionId]);
                }
            }
        }
    };

    onWindowKeyDown = (e: KeyboardEvent) => {
        let hotKey = HOT_KEYS[e.key];
        if (!hotKey) {
            return;
        }
        if (hotKey.ctrl === e.ctrlKey && hotKey.shift === e.shiftKey) {
            this[hotKey.event] && this[hotKey.event]();
        }
    };

    onRowSelected = (submissionId: number) => {
        this.props.onLoadSubmissionIfNeeded(submissionId, this.props.inboxes);
        // IMPORTANT: we need to call onSelectRow() here because we are overriding the onRowSelected from PageTableContainer.
        // and selectedRowId in the page_table reducer will not get set otherwise
        this.props.onSelectRow(submissionId);
        this.onNavigate({ submissionId });
        this.context.analytics.track(VMAC_ROW_SELECTED, { submission_id: submissionId, inbox_id: this.props.currentInbox.id });
    };

    handleShowDebugPanel() {
        let { currentSubmission } = this.props;
        let props = {
            currentSubmission: currentSubmission,
            width: DEBUG_INFO_MODAL_WIDTH,
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.DEBUG_INFO);
    }

    handleShowConflictSubmissionDebugPanel = (e: KeyboardEvent, rowData: SubmissionType) => {
        console.log('Pulling submission conflicts for: ', rowData.id);
        let props = {
            currentSubmission: rowData,
            width: DEBUG_INFO_MODAL_WIDTH,
            showClose: true,
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.SUBMISSION_CONFLICTS);
    };

    onNavigate = (pathData: Record<string, any>, replace = false, subRoute?: string) => {
        const { match } = this.props;
        let { params, path } = match;
        params = Object.assign({}, params, pathData);
        path = applyParamsToPath(path, params, subRoute, window.location.search);
        return this.props.history[replace ? 'replace' : 'push'](path);
    };

    handleOverrideAutoAction = (e: KeyboardEvent) => {
        return showConfirmThen({
            onConfirmed: () => {
                this.props.onOverrideAutoAction(this.props.currentSubmissionId);
            },
            confirmButtonTitle: 'Override',
            title: `Would you like to override this Auto-Action?`,
        })(null, e.target);
    };

    render() {
        return (
            <VikiTableCellContext.Provider value={this.getContextValues()}>
                <DashboardApp
                    onRowSelected={this.onRowSelected}
                    currentInbox={this.props.currentInbox}
                    inboxes={this.props.inboxes}
                    currentSubmissionId={this.props.currentSubmissionId}
                    currentSubmission={this.props.currentSubmission}
                    onLoadSubmissionIfNeeded={this.props.onLoadSubmissionIfNeeded}
                    centeredModalData={this.props.centeredModalData}
                    centeredModalType={this.props.centeredModalType}
                    tableData={this.props.tableData}
                    uiState={this.props.uiState}
                    me={this.props.me}
                    CONSTANTS={this.props.CONSTANTS}
                    ENV={this.props.ENV}
                    featureFlags={this.props.featureFlags}
                    openPanels={this.props.openPanels}
                    onSelectInbox={this.props.onSelectInbox}
                    onCloseCenteredModal={this.props.onCloseCenteredModal}
                    onTakeAction={this.props.onTakeAction}
                    onPopResponseModal={this.props.onPopResponseModal}
                    onPopEmailForwardModal={this.props.onPopEmailForwardModal}
                    setUiState={this.props.setUiState}
                    onOpenCenteredModal={this.props.onOpenCenteredModal}
                    onDownloadDocument={this.props.onDownloadDocument}
                    onDownloadAllSubmissionDocs={this.props.onDownloadAllSubmissionDocs}
                    onRunSOVParser={this.props.onRunSOVParser}
                    onSaveDocumentData={this.props.onSaveDocumentData}
                    onOcrImage={this.props.onOcrImage}
                    onClassifySelectedText={this.props.onClassifySelectedText}
                    onLogOut={this.props.onLogOut}
                    onTogglePanel={this.props.onTogglePanel}
                />
            </VikiTableCellContext.Provider>
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer) as any;
export default connected;
