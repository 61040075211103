import * as React from 'react';

import ForwardApp from '../apps/ForwardApp';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

const modalTypeObj = {
    Body: ForwardApp,
    Header: DefaultHeaderStatic('FORWARD'),
    className: 'forward-modal width-75',
};

registerType(ICUBED_MODAL_TYPES.FORWARD, modalTypeObj);
export default modalTypeObj;
