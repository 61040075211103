// THIS IS PROVIDED TO JSLIB VIA index.js so that constants here can be used by jslib.

import { PAGED_TABLES } from '../constants/PagedTableConfigs';

export default {
    USER_ACTIVITY_EVENT: 'client-vmac-hello',
    USER_ACTIVITY_TABLE_NAME: 'SUBMISSIONS',
    GLOBAL_PUSHER_CHANNEL: 'icubed-global-channel',
    GLOBAL_PUSHER_PRESENCE_CHANNEL: 'presence-icubed-global-channel',
    FILE_PROXY_BASE_URL: '/api/v1/file',
    DEFAULT_URL: '/dashboard',
    APP_NAME: 'Vmac',
    APP_NAME_ALT: 'Clearance',
    TABLE_CONFIGS: { ...PAGED_TABLES },
    TASK_TYPES: ['PUBLISH_TO_GATEWAY', 'SEND_REPLY', 'SEND_FORWARD', 'TAKE_BOR_ACTION', 'WRITE_TO_VIKI', 'WRITE_TO_ARCHLINK'],
};
