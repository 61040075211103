import * as React from 'react';
import _ from 'lodash';

import LiteTableContainer from '@ventusrisk/jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { EXTERNAL_SOURCES } from '../../../../constants/Constants';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

type propTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onClickDuplicateInTable: (sid: number, rowIndex: number, rowData: any) => void;
};

export default class DuplicatesTableApp extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    onGoToDupe = (sid: number, rowIndex: any, rowData: any) => {
        const { source } = rowData;
        let submissionId = sid;
        // VIKI-8224
        if (source === EXTERNAL_SOURCES.VIKI || source === EXTERNAL_SOURCES.ARCH) {
            submissionId = rowData.external_id;
        }
        _.delay(() => {
            this.props.onClickDuplicateInTable(submissionId, rowIndex, rowData);
        }, 1000);
    };

    render() {
        let { currentSubmission, tableData } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <LiteTableContainer
                onRowSelected={null}
                hasActionPanel={false}
                tableConfig={LITE_TABLES.DUPLICATESUBMISSIONS}
                containerClass="standard-modal-content"
                tableData={tableData}
                actionHelperFns={{
                    onGoToDupe: this.onGoToDupe,
                }}
            />
        );
    }
}
