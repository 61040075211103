import _ from 'lodash';
import { AxiosResponse } from 'axios';

import { API } from '@ventusrisk/jslib';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { loadLiteTableRows } from '@ventusrisk/jslib/src/actions/TableActions';
import * as converters from '@ventusrisk/jslib/src/utils/converters';
import * as types from '../constants/ActionTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';

type DeltaRecords = {
    changes: any;
    id: number;
    object_verbose_name: string;
};

const FOREIGN_KEY_CONSTANTS_MAP = {
    producer: 'PRODUCERS',
    producing_agent: 'PRODUCING_AGENTS',
    underwriter: 'UNDERWRITERS',
    assigned_to: 'CLEARANCE_USERS',
    originating_underwriter: 'UNDERWRITERS',
    licensed_agent: 'PRODUCING_AGENTS',
    insured: 'INSUREDS',
};

const FOREIGN_KEY_FIELDS = Object.keys(FOREIGN_KEY_CONSTANTS_MAP);

export const getForeignKeyDisplay = (val: any, field: string, CONSTANTS: Record<string, any>) => {
    let choices = CONSTANTS[FOREIGN_KEY_CONSTANTS_MAP[field]];
    val = parseInt(val, 10);
    let found = _.find(choices, function (p) {
        return p[0] === val;
    });

    return found ? found[1] : val;
};

export const formatRecordField = (val: any, fieldData: Record<string, any>, CONSTANTS: Record<string, any>) => {
    if (val === null) {
        return val;
    }

    let type = (fieldData.field_format && fieldData.field_format.subtype) || fieldData.type;
    switch (type) {
        case 'date': {
            return converters.formatDateString(val);
        }
        case 'datetime': {
            return converters.formatDateTimeString(val);
        }
        case 'currency':
            return converters.formatCurrency(val);
        case 'percentage':
        case 'number':
            if (FOREIGN_KEY_FIELDS.indexOf(fieldData.field_name) !== -1) {
                return getForeignKeyDisplay(val, fieldData.field_name, CONSTANTS);
            }
            return parseInt(val, 10);
        case 'text': {
            if (fieldData.choices && fieldData.choices.length) {
                const vals = _.find(fieldData.choices, d => {
                    return d[0] === val;
                });
                return vals ? vals[1] : val;
            }
            return val;
        }
        case 'json': {
            return JSON.stringify(val);
        }
        case 'bool': {
            return JSON.stringify(val);
        }
        case 'masked':
        case 'textarea':
        default:
            return val;
    }
};

export const processRecord = (record: Record<string, any>, tableFields: Record<string, any>, CONSTANTS: Record<string, any>) => {
    // NOTE, need to iterate over each change in the changes dict so they have unique row in the table
    let index = 0;
    let results = [];
    for (let key in record.changes) {
        let changeData = record.changes[key];
        let fieldData = tableFields.map[key];
        results.push({
            ...record,
            id: `${record.id}-${index}`,
            change_new: formatRecordField(changeData.new, fieldData, CONSTANTS),
            change_old: formatRecordField(changeData.old, fieldData, CONSTANTS),
            change_reason: changeData.change_reason,
            change_field: fieldData.verbose_name,
            changes: null,
        });
        index++;
    }
    return results;
};

export const processDeltaRecords = (deltaRecords: DeltaRecords[], glossarys: Record<string, any>, CONSTANTS: Record<string, any>) => {
    let rows = [];
    for (let idx in deltaRecords) {
        let record = deltaRecords[idx];
        rows = rows.concat(processRecord(record, glossarys[record.object_verbose_name.toLowerCase().replace(' ', '_')], CONSTANTS));
    }
    return rows;
};

export const getDeltaRecords = (submissionId: number): any => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        const state = getState();
        let glossarys = state.debug.glossarys;
        let insuredReferences = state.debug.insuredReferences;
        let request = {
            submission_id: submissionId,
        };
        const { CONSTANTS } = getState().global;
        return API.doRequest(API.endpoints.submission.getDeltaRecords, request, dispatch).then(({ data }) => {
            dispatch(
                loadLiteTableRows(LITE_TABLES.DELTARECORDS, processDeltaRecords(data.delta_records, glossarys, { ...CONSTANTS, INSUREDS: insuredReferences }))
            );
        });
    };
};

export const setGlossaries = (data: Record<string, any>[]) => {
    return {
        type: types.SET_GLOSSARIES,
        data: data,
    };
};

export const setInsuredReferences = (data: Record<string, any>[]) => {
    return {
        type: types.SET_INSURED_REFERENCES,
        data: data,
    };
};

export const getGlossarys = (tables: string[]): any => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.table.getGlossarys,
            {
                params: {
                    tables: tables,
                },
            },
            dispatch
        ).then(
            response => {
                dispatch(setGlossaries(response.data));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};

export const getInsuredReferences = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            id: submissionId,
        };
        return API.doRequest(API.endpoints.submission.getInsuredReferences, request, dispatch).then((response: AxiosResponse) => {
            dispatch(setInsuredReferences((response as any).data));
        });
    };
};
