import React, { useState } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { useDispatch } from 'react-redux';
import { updeepUi } from '@ventusrisk/jslib/src/actions/CommonActions';
import { useParams } from 'react-router-dom';

import AddResponseApp from '../apps/AddResponseApp';
import { ComboBoxInput } from '@ventusrisk/jslib/src/components/inputs';
import { takeAction } from '../../../../actions/SubmissionActions';
import { closeCenteredModal } from '@ventusrisk/jslib/src/actions/GlobalActions'; // openCenteredModal
import { getTemplate_server, putTemplate_server } from '../../../../actions/EmailActions';
import { ACTIONS_MAPPING, EMPTY_RESPONSE_OBJECT, DECLINED_BOR_ACTION, CLEARED_BOR_ACTION } from '../../../../constants/Constants';
// import { SubmissionType } from '../../../../ts-types/DataTypes';
import { useGetBorDataQuery } from '../../../../services/apiSlice';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';

const ResolvePendingBORApp = () => {
    const dispatch = useDispatch();
    const { submissionId: currentSubmissionId } = useParams<{ submissionId: string }>();
    const { data: borData, isLoading } = useGetBorDataQuery({ submissionId: currentSubmissionId });

    const losingSubmissionResponse = useAppSelector(state => {
        return state.uiState?.LOSINGRESPONSE;
    });
    const winningSubmissionResponse = useAppSelector(state => state.uiState?.WINNINGRESPONSE);
    const sendEmailNotification = useAppSelector(state => state.uiState?.emailNotification?.shouldSend);
    const [winningSubmissionData, setWinningSubmissionData] = useState({ id: currentSubmissionId });
    const [losingSubmissionAdditionalEmails, setLosingingSubmissionAdditionalEmails] = useState([]);
    const [winningSubmissionAdditionalEmails, setWinningSubmissionAdditionalEmails] = useState([]);

    const winningSubmissionID = winningSubmissionData.id;
    const winningSubmission = isLoading ? null : borData.submissions.filter(subData => subData.id === winningSubmissionID)[0];
    const getLosingSubmissions = () => (isLoading ? [] : borData.submissions.filter(subData => subData.id !== winningSubmissionID));

    const getWinningTemplate = () => dispatch(getTemplate_server({ submissionId: winningSubmissionID, action: CLEARED_BOR_ACTION }, 'WINNINGRESPONSE'));
    const putWinningTemplate = body => dispatch(putTemplate_server({ submissionId: winningSubmissionID, action: CLEARED_BOR_ACTION, body }));
    const getLosingTemplate = () => dispatch(getTemplate_server({ submissionId: winningSubmissionID, action: DECLINED_BOR_ACTION }, 'LOSINGRESPONSE'));
    const putLosingTemplate = body => dispatch(putTemplate_server({ submissionId: winningSubmissionID, action: DECLINED_BOR_ACTION, body }));
    const onResetTemplate = () => {
        getWinningTemplate();
        getLosingTemplate();
    };

    const onClose = () => {
        dispatch(closeCenteredModal());
    };
    const onClearFormData = () => {
        dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, 'WINNINGRESPONSE'));
        dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, 'LOSINGRESPONSE'));
    };

    const onResolvePendingBOR = (): any => {
        return dispatch(
            takeAction(
                currentSubmissionId,
                ACTIONS_MAPPING.submission_resolve_pending_bor,
                winningSubmissionResponse,
                winningSubmissionAdditionalEmails,
                sendEmailNotification,
                [winningSubmissionID],
                losingSubmissionResponse,
                losingSubmissionAdditionalEmails
            )
        );
    };

    const onTakeAction = (_action: string, _emailParams: any, _ccEmails?: string[], _replyToEmails?: string[]): PromiseDispatchType => {
        onClose();
        return onResolvePendingBOR();
    };

    const handleChangeWinner = (_key: string, value: any) => {
        setWinningSubmissionData({ id: value });
    };

    const handleAdditionalEmailsChange = (emails: string[], submissionId: number) => {
        if (String(submissionId) === winningSubmissionID) {
            setWinningSubmissionAdditionalEmails(emails);
        } else {
            setLosingingSubmissionAdditionalEmails(emails);
        }
    };

    const baseProps = {
        onClose,
        onTakeAction,
        onClearFormData,
        // currentSubmission,
        onSearchInbox: null,
        onSendResponse: null,
        handleAdditionalEmailsChange: handleAdditionalEmailsChange,
    };

    let allSids = [];
    if (!isLoading) {
        const formatSubmissionChoice = subData => {
            let label = `${subData.id} - ${subData.office}`;
            if (subData.id === borData.incumbent_submission_id) {
                label = `${label} (incumbent)`;
            }
            return [subData.id, label];
        };
        allSids = borData.submissions.map(formatSubmissionChoice);
    }
    const winningProps = {
        ...baseProps,
        uiState: {
            RESPONSE: winningSubmissionResponse,
        },
        sendButtonText: 'Send Emails',
        onGetTemplate: getWinningTemplate,
        onPutTemplate: putWinningTemplate,
        onResetTemplate,
        modalData: {
            replyToEmail: winningSubmission?.reply_to_email,
            currentSubmissionId: winningSubmissionID,
            action: CLEARED_BOR_ACTION,
            additionalCCEmails: [winningSubmission?.underwriter_email],
        },
    };

    const losingProps = {
        ...baseProps,
        uiState: {
            RESPONSE: losingSubmissionResponse,
        },
        hideButtons: true,
        disableCCEmails: true,
        onGetTemplate: getLosingTemplate,
        onPutTemplate: putLosingTemplate,
        onResetTemplate,
        modalData: {
            action: DECLINED_BOR_ACTION,
            currentSubmissionId: null,
            replyToEmail: null,
            additionalCCEmails: getLosingSubmissions()?.map(sub => sub.underwriter_email),
            additionalReplyToEmails: getLosingSubmissions()?.map(sub => sub.reply_to_email),
        },
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
            <div className="flex f-row pending-bor-resolution-winner">
                <label>Winning Submission: </label>
                <ComboBoxInput
                    name="id"
                    noWrapper={true}
                    onChange={handleChangeWinner}
                    object={winningSubmissionData}
                    selectProps={{ choices: allSids, nullable: false }}
                />
            </div>
            {sendEmailNotification && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AddResponseApp {...losingProps} />
                    <AddResponseApp {...winningProps} />
                </div>
            )}
            {!sendEmailNotification && (
                <div className="button-row">
                    <button className="green" onClick={() => onTakeAction(null, null, null, null)}>
                        Resolve
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="red"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResolvePendingBORApp;
