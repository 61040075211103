import * as React from 'react';
import { connect } from 'react-redux';

import { SubmissionType, InboxType, UserType, ApiStatusType } from '../../ts-types/DataTypes';
import { PagedTableDataType, RowSelectedFnType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { getApiStatuses, getAccountConflicts } from '../../actions/ArchActions';
import { expandApiStatus } from '../../utils/account-and-submission-conflct-utils';
import { persistRowData_server, toggleAllRowsChecked } from '@ventusrisk/jslib/src/actions/TableActions';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { processGridRequestWithId, processGridRequestWithIds } from '@ventusrisk/jslib/src/utils/api/RequestUtils';
import { Types } from '../../ts-types/icubed-types';

import InboxPanel from './InboxPanel';

type ownPropTypes = {
    currentSubmission?: SubmissionType;
    currentSubmissionId?: number;
    tableData: PagedTableDataType;
    currentInbox: InboxType;
    uiState: any;
    me: UserType;
    CONSTANTS: Types.Constants;
    inboxes: InboxType[];

    onTakeAction: (id: number, action: string, params: Record<string, any>, sendEmailNotification: boolean) => void;
    onPopEmailForwardModal: (params: Record<string, any>) => void;
    onPopResponseModal: (params: Record<string, any>) => void;
    onRowSelected: RowSelectedFnType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    setUiState: (params: Record<string, any>, path: string) => void;
};
type propTypes = {
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
    ENV: Types.Env;
    featureFlags: Record<string, boolean>;
    onSaveFn: (fieldValueMap: any) => Promise<any>;
    onGetApiStatuses: () => void;
    onGetAccountConflicts: () => void;
    onBatchSaveFn: (field: string, value: any) => Promise<any>;
    onToggleAllCheckedRows: () => void;
} & ownPropTypes;

const mapStateToProps = (state: Record<string, any>, ownProps: ownPropTypes) => {
    const { apiStatuses } = ownProps.currentSubmission;
    const { featureFlags, CONSTANTS, ENV } = state.global;

    return {
        ENV,
        featureFlags,
        _arch_integration:
            {
                submissionStatuses: expandApiStatus(apiStatuses && apiStatuses['submission'], CONSTANTS),
                accountStatuses: expandApiStatus(apiStatuses && apiStatuses['account'], CONSTANTS),
                apiStatuses,
            } || {},
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: ownPropTypes) => {
    const { currentSubmission, tableData } = ownProps;

    return {
        onGetApiStatuses: () => {
            if (ownProps.currentSubmissionId) dispatch(getApiStatuses(ownProps.currentSubmissionId));
        },
        onSaveFn: (fieldValueMap): Promise<any> => {
            return dispatch(persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(currentSubmission.id, fieldValueMap, tableData.rowData)));
        },
        onBatchSaveFn: (field, value) => {
            return dispatch(
                persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithIds(tableData.checkedRows, { [field]: value }, tableData.rowData))
            );
        },
        onGetAccountConflicts: () => {
            if (ownProps.currentSubmissionId) dispatch(getAccountConflicts(ownProps.currentSubmissionId));
        },
        onToggleAllCheckedRows: () => {
            return dispatch(toggleAllRowsChecked(PAGED_TABLES.SUBMISSIONS, Object.keys(tableData.checkedRows).map(Number)));
        },
    };
};

class InboxPanelContainer extends React.Component<propTypes> {
    componentDidUpdate(prevProps: propTypes) {
        if (
            (this.props.currentSubmission.is_arch_integration_enabled && prevProps.currentSubmissionId !== this.props.currentSubmissionId) ||
            prevProps.currentSubmission.arch_clearance_api_status !== this.props.currentSubmission.arch_clearance_api_status
        ) {
            console.info('(STATUS): changed in panel');
            this.props.onGetApiStatuses();
        }
    }

    render() {
        return (
            <InboxPanel
                currentSubmission={this.props.currentSubmission}
                currentSubmissionId={this.props.currentSubmissionId}
                tableData={this.props.tableData}
                inboxes={this.props.inboxes}
                currentInbox={this.props.currentInbox}
                uiState={this.props.uiState}
                me={this.props.me}
                CONSTANTS={this.props.CONSTANTS}
                ENV={this.props.ENV}
                featureFlags={this.props.featureFlags}
                _arch_integration={this.props._arch_integration}
                onTakeAction={this.props.onTakeAction}
                onPopEmailForwardModal={this.props.onPopEmailForwardModal}
                onPopResponseModal={this.props.onPopResponseModal}
                onRowSelected={this.props.onRowSelected}
                onOpenCenteredModal={this.props.onOpenCenteredModal}
                setUiState={this.props.setUiState}
                onBatchSaveFn={this.props.onBatchSaveFn}
                onToggleAllCheckedRows={this.props.onToggleAllCheckedRows}
                onSaveFn={this.props.onSaveFn}
            />
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(InboxPanelContainer) as any;
export default connected;
