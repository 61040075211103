export const ICUBED_MODAL_TYPES = {
    ACCOUNT_SEARCH: 'ACCOUNT_SEARCH',
    ADD_ACCOUNT_BROKER: 'ADD_ACCOUNT_BROKER',
    ADD_NOTE: 'ADD_NOTE',
    ADD_RESPONSE: 'ADD_RESPONSE',
    BUILDING_DISTRUBTIONS: 'BUILDING_DISTRUBTIONS',
    BROKER_MATCHING: 'BROKER_MATCHING',
    CLIENT_MATCHING: 'CLIENT_MATCHING',
    DEBUG_INFO: 'DEBUG_INFO',
    DUPLICATE_SUBMISSIONS: 'DUPLICATE_SUBMISSIONS',
    FORWARD: 'FORWARD',
    MAP_SCHEDULE_VIEW: 'MAP_SCHEDULE_VIEW',
    OVERRIDE_BUSINESS_RULES: 'OVERRIDE_BUSINESS_RULES',
    OCR_RESULTS: 'OCR_RESULTS',
    SUBMISSION_CONFLICTS: 'SUBMISSION_CONFLICTS',
    RESOLVE_PENDING_BOR: 'RESOLVE_PENDING_BOR',
    BLOCK_SUBMISSION: 'BLOCK_SUBMISSION',
    BOR_SUBMISSION_MATCHING: 'BOR_SUBMISSION_MATCHING',
    BOR_NOTIFICATION: 'BOR_NOTIFICATION',
};

export const DUPLICATE_MODAL_WIDTH = 1600;
export const DEBUG_INFO_MODAL_WIDTH = 1300;
export const BOR_MATCHING_MODAL_WIDTH = 1100;
export const BROKER_MATCHING_MODAL_WIDTH = 1000;
