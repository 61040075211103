import _ from 'lodash';
import { API } from '@ventusrisk/jslib';
import { UserType } from '../ts-types/DataTypes';
import { closeCenteredModal, openCenteredModal, openMessageModal, closeMessageModal } from '@ventusrisk/jslib/src/actions/GlobalActions';
import { CENTERED_MODAL_TYPES } from '@ventusrisk/jslib/src/constants/Constants';
import { DispatchType } from '@ventusrisk/jslib/src/ts-types/GlobalTypes';
import { updeepUi } from '@ventusrisk/jslib/src/actions/CommonActions';

import { ICUBED_MODAL_TYPES } from '../constants/Modals';
import { EMPTY_FWD_OBJECT } from '../constants/Constants';
import * as types from '../constants/ActionTypes';
import { getSubmissionDataFromServer } from './SubmissionActions';

const DISMISS_MODAL_IN_SECONDS = 3;
export const dismissModalInAFewSeconds = (dispatch: DispatchType) => {
    _.delay(() => {
        dispatch(closeMessageModal(0));
    }, DISMISS_MODAL_IN_SECONDS * 1000);
};

export const setReplyTemplate = (template: UserType) => {
    return {
        type: types.SET_REPLY_TEMPLATE,
        template: template,
    };
};

export const updateEmailSentStatus = (dispatch: DispatchType, message?: string) => {
    dispatch(
        openMessageModal(
            {
                title: message,
                disableModalResizable: true,
            },
            CENTERED_MODAL_TYPES.CONFIRM
        )
    );
};

export const sendEmailResponse_server = (submissionId: number, params: any) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.sendEmail,
            {
                params: { ...params, submissionId },
                hideDialogForErrors: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                updateEmailSentStatus(dispatch, `Email succcessfully sent to ${data.to_email}`);
                dismissModalInAFewSeconds(dispatch);
                dispatch(getSubmissionDataFromServer(submissionId));
                return data;
            },
            xhr => {
                return xhr;
            }
        );
    };
};

export const resendEmail = (emailId: number, submissionId: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.resendEmail,
            {
                emailId: emailId,
            },
            dispatch
        ).then(
            ({ data }) => {
                updateEmailSentStatus(dispatch, `${data.detail}`);
                dismissModalInAFewSeconds(dispatch);
                dispatch(getSubmissionDataFromServer(submissionId));
                return data;
            },
            xhr => {
                return xhr;
            }
        );
    };
};

export const forwardToViki_server = (submissionId: number, params: any) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.fwdToViki,
            {
                params: { ...params, clearance_submission_id: submissionId },
                hideDialogForErrors: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                updateEmailSentStatus(dispatch, `Email succcessfully sent to ${data.to_email}`);
                dismissModalInAFewSeconds(dispatch);
                dispatch(getSubmissionDataFromServer(submissionId));
                dispatch(updeepUi(EMPTY_FWD_OBJECT, 'FWD'));
                dispatch(closeCenteredModal());
                return data;
            },
            xhr => {
                dispatch(
                    updeepUi({
                        FWD: { _errors: xhr.data.errors },
                    })
                );
                return xhr;
            }
        );
    };
};

export const getTemplate_server = (params: any, templateKey = 'RESPONSE') => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.getTemplate,
            {
                params: params,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updeepUi({ body: data.body, submissionId: params.submissionId, action: params.action }, templateKey));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};

export const putTemplate_server = (params: any) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.putTemplate,
            {
                params: params,
            },
            dispatch
        );
    };
};

export const popEmailResponseModal = (data: any) => {
    let defaultTypes = {
        submissionId: data.currentSubmissionId,
        // onOk: () => {},
        // onCancel: () => {},
    };
    return (dispatch: DispatchType) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: Object.assign({}, defaultTypes, data),
                },
                ICUBED_MODAL_TYPES.ADD_RESPONSE
            )
        );
    };
};

export const popEmailForwardModal = (data: any) => {
    let defaultTypes = {
        submissionId: data.currentSubmissionId,
    };
    return (dispatch: DispatchType) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: Object.assign({}, defaultTypes, data),
                },
                ICUBED_MODAL_TYPES.FORWARD
            )
        );
    };
};
