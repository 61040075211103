import '../../../../styles/modals/bor_submission_matching.scss';
import * as React from 'react';
import _ from 'lodash';

import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import LiteTableContainer from '@ventusrisk/jslib/src/containers/LiteTableContainer';

import { SubmissionType } from '../../../../ts-types/DataTypes';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { BOR_NOTIFICATION_ACTION } from '../../../../constants/Constants';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';

type propTypes = {
    tableData: LiteTableDataType;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    onSearchSubmissions: (query: string) => PromiseDispatchType;
    onTakeAction: (submissionId: number, relatedSubmissionIds: string[]) => PromiseDispatchType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    sendEmailNotification: boolean;
};
type stateTypes = {
    incumbentId: string;
    showProgress: boolean;
    incubmentBroker: string;
    sendEmailNotification: boolean;
    borSubmissions: string[];
    setByRenewal: boolean;
};

export default class BorSubmissionMatchingApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        const incumbentId = String(props.currentSubmission.id);
        const incubmentBroker = `${props.currentSubmission.account_agent_first_name} ${props.currentSubmission.account_agent_last_name}`;
        const sendEmailNotification = props.sendEmailNotification;
        this.state = {
            showProgress: true,
            borSubmissions: [incumbentId],
            incumbentId,
            incubmentBroker,
            setByRenewal: false,
            sendEmailNotification,
        };

        this.doSearch();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData.rows !== this.props.tableData.rows) {
            prevProps.tableData.rows.map(sid => this.setIncumbentIfRenewal(String(sid)));
        }
    }

    setIncumbentIfRenewal = (sid: string) => {
        const rowData: object = this.props.tableData.rowData[sid];
        if (rowData && rowData['renewal_of']) {
            this.onMarkPendingBor(sid);
            this.onSetIncumbentId(sid);
            if (!this.state.setByRenewal) {
                this.setState({ setByRenewal: true });
            }
        }
    };

    handleCancel = () => {
        this.props.onClose(true);
    };

    resetSearchValue = () => {
        this.doSearch();
    };

    doSearch = _.debounce(() => {
        this.setState({ showProgress: true });
        this.props.onSearchSubmissions(`A${this.props.currentSubmission.arch_account_arch_id}`).finally(() => {
            this.setState({ showProgress: false });
        });
    }, 500);

    handleChange = (_field: string, e: any) => {
        this.doSearch();
    };

    getBorSubmissions = () => {
        return this.state.borSubmissions.filter(sid => sid !== this.state.incumbentId);
    };

    removeBORSubmission(sidToRemove: string) {
        if (sidToRemove !== this.state.incumbentId) {
            this.setState({ borSubmissions: this.state.borSubmissions.filter(sid => sid !== sidToRemove) });
        }
    }

    onMarkPendingBor = (sid: string) => {
        const isCurrentSubmission = String(sid) === String(this.props.currentSubmission.id);
        const isIncumbentSubmission = String(sid) === String(this.state.incumbentId);
        if (isCurrentSubmission || isIncumbentSubmission) {
            return;
        }
        if (this.state.borSubmissions.includes(sid)) {
            this.removeBORSubmission(sid);
        } else {
            this.setState({ borSubmissions: [...this.state.borSubmissions, sid] });
        }
    };
    getOldestReceivedSubmission = () => {
        if (this.getBorSubmissions()) {
            let borSubs = this.getBorSubmissions().map(String);
            let filteredRowData = Object.fromEntries(Object.entries(this.props.tableData.rowData).filter(([key]) => borSubs.indexOf(key) !== -1));
            return Object.values(filteredRowData).reduce((r, o) => (o.date_received < r.date_received ? o : r));
        }
    };
    getIncumbentSubmission = () => {
        return this.props.tableData.rowData[this.state.incumbentId];
    };

    onMarkPendingBorClicked = (sendEmailNotification: boolean) => {
        const incumbentSubmission = this.getIncumbentSubmission();
        const oldestSubmission = this.getOldestReceivedSubmission();
        let borSubmissions = this.getBorSubmissions();
        borSubmissions = borSubmissions.map(sid => String(sid)).filter(elem => elem !== String(oldestSubmission.id));
        borSubmissions.unshift(String(this.state.incumbentId));
        borSubmissions.push(String(oldestSubmission.id));

        if (incumbentSubmission) {
            if (sendEmailNotification) {
                this.props.onOpenCenteredModal(
                    {
                        oldestSubmission,
                        incumbentSubmission,
                        borSubmissions: borSubmissions,
                        modalData: {
                            action: BOR_NOTIFICATION_ACTION,
                            currentSubmissionId: this.props.currentSubmission.id,
                        },
                    },
                    ICUBED_MODAL_TYPES.BOR_NOTIFICATION
                );
            } else {
                _.delay(() => {
                    // ensure the incumbent is the first element in borSubmissions
                    this.props.onTakeAction(this.props.currentSubmission.id, borSubmissions);
                }, 1000);
                this.props.onClose(true);
            }
        }
    };

    onSetIncumbentId = (id: string) => {
        if (id !== this.state.incumbentId && !this.state.setByRenewal) {
            const { currentSubmission } = this.props;
            const submissionData = this.props.tableData.rowData[id];
            const incumbentBroker = submissionData.id === currentSubmission.id ? currentSubmission.account_agent_name : submissionData.broker_name;
            this.setState({ incumbentId: id, incubmentBroker: incumbentBroker as string });
        }
    };

    shouldDisableMarkPendingBor = () => {
        const borContainsAtLeastOneVmacSubmission = this.state.borSubmissions.some(sid => sid[0] !== 'S');
        const incumbentIncludedInBor = this.state.borSubmissions.some(element => element === this.state.incumbentId);
        const moreThanTwoSubmissionsSelected = this.state.borSubmissions.length > 1;

        return !incumbentIncludedInBor || !moreThanTwoSubmissionsSelected || !borContainsAtLeastOneVmacSubmission;
    };

    render() {
        let { currentSubmission, tableData } = this.props;
        if (!currentSubmission) {
            return null;
        }

        const markPendingBorDisabled = this.shouldDisableMarkPendingBor();

        return (
            <div className="standard-modal-content flex column">
                <div className="flex-table-row row clmn-headers padding-right">
                    <div className="width-100 padding-standard">
                        <label>Arch Account ID</label>
                        <div className="tbl-attachment margin-right">
                            <div className="attached-item search-bar grow">
                                <div className="icon icon-search"></div>
                                <input value={`A${currentSubmission.arch_account_arch_id}`} disabled></input>
                            </div>
                        </div>
                    </div>
                </div>

                <LiteTableContainer
                    onRowSelected={null}
                    tableData={tableData}
                    hasActionPanel={false}
                    containerClass="standard-modal-content width-100 no-padding-top h-adjust"
                    tableConfig={LITE_TABLES.BORSUBMISSIONS}
                    disableTable={this.state.showProgress}
                    actionHelperFns={{
                        onMarkPendingBor: this.onMarkPendingBor,
                    }}
                    extraCellRenderData={{ currentSubmission, borSubmissions: this.state.borSubmissions }}
                />

                <div className="fixed-bottom-panel">
                    <div className={'suspected-dupes flex ai_c jc_fe pending_bor'}>
                        <div className="handle pad-right-05">
                            <span className="text">BOR Submissions</span>
                        </div>
                        {this.state.borSubmissions.map(sid => (
                            <div key={sid} className={`badge badge-${this.state.incumbentId === sid ? 'warning' : 'info'} filter-tag`}>
                                <span onClick={() => this.onSetIncumbentId(sid)}> {sid} </span>
                                <span className="close-x icon-close small" onClick={() => this.onMarkPendingBor(sid)} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'suspected-dupes flex ai_c jc_sb pending_bor_panel'}>
                    <span>
                        Incumbent Submission ID: {this.state.incumbentId}, Broker: {this.state.incubmentBroker}
                    </span>
                    <div className="button-row">
                        <button
                            className="green"
                            disabled={markPendingBorDisabled}
                            onClick={() => this.onMarkPendingBorClicked(this.state.sendEmailNotification)}
                        >
                            Mark Pending Bor
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
