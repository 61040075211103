import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import BrokerMatching from '../containers/BrokerMatching.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@ventusrisk/jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@ventusrisk/jslib/src/ts-types/TableTypes';
import { DefaultHeaderWithProps } from '@ventusrisk/jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

class BrokerMatchingBody extends React.Component<bodyPropTypes> {
    render() {
        return <BrokerMatching {...this.props} />;
    }
}
const modalTypeObj = {
    Body: BrokerMatchingBody,
    Header: DefaultHeaderWithProps('Broker Matching'),
    className: 'client-matching-modal',
};

registerType(ICUBED_MODAL_TYPES.BROKER_MATCHING, modalTypeObj);
export default modalTypeObj;
