import { commonApi } from '@ventusrisk/jslib/src/services/commonApi';

type Result = any;
type QueryOptions = any;

const apiWithTags = commonApi.enhanceEndpoints({
    addTagTypes: [],
});

const api = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        updateUserSettings: builder.mutation<Result, QueryOptions>({
            query: ({ data }) => ({ url: `v1/user_settings/toggle_left_panel/`, method: 'POST', data }),
        }),
        getGlobalStatus: builder.query<any, any>({
            query: () => ({ url: `v1/global/status`, method: 'GET' }),
        }),
        validate: builder.query<any, any>({
            query: ({ submissionId }) => ({ url: `v1/submission_data/${submissionId}/validate/`, method: 'GET' }),
            providesTags: (_result, _err, { submissionId }) => [{ type: 'SubmissionValidation', id: submissionId }],
        }),
        getBorData: builder.query<any, any>({
            query: ({ submissionId }) => ({
                method: 'GET',
                url: `v1/submission/${submissionId}/get_bor_data/`,
            }),
        }),
    }),
});

export default api;
export const { usePrefetch, useUpdateUserSettingsMutation, useGetGlobalStatusQuery, useGetBorDataQuery, useValidateQuery } = api;
