import * as React from 'react';
import _ from 'lodash';

import { determineRowColor, getBlockingExternalSubmissionUrl } from '../utils/row-helpers';
import { DISPLAY_DATE_TIME_AM_PM_S, DEFAULT_DATETIME_FORMAT, DISPLAY_DATE_FORMAT } from '@ventusrisk/jslib/src/constants/Constants';
import { EXTERNAL_SOURCES, UNKNOWN, CLEARANCE_STATUS, ARCH_CONFLICT_ERRORS, SUBDIVISION_CODES } from '../constants/Constants';
import { UnderwritingPlatformType } from '../ts-types/DataTypes';
import { getAccountNumberFromString, getDomainIdForEnv } from '@ventusrisk/jslib/src/utils/arch-helpers';
import { GridCheckMark } from '../components/misc/GridCheckMark';
import moment from 'moment';
import { ColumnType, TableGlossaryType } from '@ventusrisk/jslib/src/ts-types/TableTypes';

const SELECT_COL_PROPS: ColumnType = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, field_name }: any = {}) => {
                if (currentSubmission && currentSubmission[field_name] === rowData.id) {
                    return <GridCheckMark />;
                }
                return '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
};

const SELECT_ARCH_COL_PROPS = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission }: any = {}) => {
                if (rowData.selected) {
                    return <GridCheckMark />;
                }
                return currentSubmission && rowData.id === currentSubmission.arch_account.is_suspended ? 'CREATE NEW' : '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
};

const SELECT_ARCH_ACCOUNT_COL_PROPS = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, field_name }: any = {}) => {
                return currentSubmission && currentSubmission[field_name] === rowData.arch_id ? <GridCheckMark /> : '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
};

const ID_COL_PROPS = {
    w: 100,
    field_name: 'id',
    name: 'ID',
    readonly: true,
    field_format: { nocommas: true },
    classNameFn: (rowData: any) => determineRowColor(rowData),
    show_others_viewing_icon: true,
};

const ID_COLOR_HELPER = {
    classNameFn: (rowData: any) => determineRowColor(rowData),
};

const INSURED_READONLY = (rowData: any, extraData: any = {}) => {
    const isArchAccount = rowData.arch_account_arch_id;
    const isSuspendedArchAccount =
        isArchAccount && rowData.arch_account && rowData.arch_account.status && rowData.arch_account.status.toLowerCase().startsWith('s');

    if (!rowData.is_editable) {
        return true;
    }

    if (isArchAccount && !isSuspendedArchAccount) {
        return !extraData.insuredEditableOverride;
    }

    return false;
};

const INSURED_READONLY_CLIENT_MATCHING = (rowData: any, extraData: any = {}) => {
    const currentSubmission = extraData.currentSubmission;
    return !currentSubmission.is_editable || !currentSubmission.arch_account_id;
};

const SUBMISSION_READONLY = (rowData: any, extraData: Record<string, any>) => {
    const { me } = extraData;
    const userPermittedToEdit = me && (!rowData.assigned_to_id || rowData.assigned_to_id === me.id);

    return !rowData.is_editable || !userPermittedToEdit;
};

const BROKER_READONLY = (rowData: any) => !!rowData.account_agent_import_object_id;

const REPLY_TO_READONLY = (rowData: any) => {
    return rowData.reply_to_email_disabled;
};

const DAYS_FROM_RECEIPT = 120;

const STREET = {
    w: 250,
    field_name: 'street',
    short_name: 'Street',
};

const CITY = {
    w: 100,
    field_name: 'city',
    short_name: 'City',
};

const STATE = {
    w: 40,
    field_name: 'state',
    short_name: 'ST',
};

const ZIPCODE = {
    w: 55,
    field_name: 'zipcode',
    short_name: 'ZIP',
};

const FEIN = {
    w: 90,
    field_name: 'fein',
    short_name: 'Fein',
};

const IMPORT_OBJECT_ID = {
    w: 80,
    field_name: 'import_object_id',
    short_name: 'VIKI ID',
};

function extraIdContent(value: any, rowData: any, _col: ColumnType, onClickEvents?: Record<string, any>, _extraData?: any) {
    // VIKI-extraIdContent
    let badges = [];

    const hasValidations = rowData._validations && !_.isEmpty(rowData._validations.WARN);
    const isBlocked = rowData.status === CLEARANCE_STATUS.BLOCKED;
    const isArchieved = rowData.status === CLEARANCE_STATUS.ARCHIVED;
    const isDeclined = rowData.status === CLEARANCE_STATUS.DECLINED;
    const isArchSubmissionConflict =
        hasValidations && rowData._validations.WARN.id && rowData._validations.WARN.id.join(' ').includes(ARCH_CONFLICT_ERRORS.SUBMISSION_CONFLICT);

    if (rowData.is_likely_blocked) {
        badges.push(
            <div key="is-likely-blocked" className="badge badge-blocked margin-left-05" title="Likely blocked">
                B
            </div>
        );
    } else if (!hasValidations && !rowData.date_cleared && !(isBlocked || isArchieved || isDeclined)) {
        badges.push(
            <div key="is-ready-for-clear" className="badge badge-clear margin-left-05" title="Eligible to clear">
                C
            </div>
        );
    }

    if (isArchSubmissionConflict) {
        const handleClick = () => {
            onClickEvents && onClickEvents['is-arch-submission-conflict-error'](value, rowData);
        };

        badges.push(
            <div onClick={handleClick} key="is-arch-submission-conflict-error" className="badge badge-grey margin-left-05" title="Arch Submission Conflict">
                SC
            </div>
        );
    }

    return badges;
}

function extraStatusContent(value: any, rowData: any, _colId: any, onClickEvents?: Record<string, any>, extraData?: any) {
    if (rowData.source === EXTERNAL_SOURCES.VIKI || rowData.source === EXTERNAL_SOURCES.ARCH) {
        // VIKI-8224 : if this is an external submission then return no badges
        return [];
    }

    let badges = [];
    const FEATURE_FLAGS = extraData ? extraData.FEATURE_FLAGS : null;
    if (rowData.external_last_status_change && !rowData.has_new_message && !rowData.is_archived) {
        badges.push(
            <div key="message-set-externally" className="badge badge-success margin-left-05" title="Status set externally">
                E
            </div>
        );
    }

    if (rowData.has_new_message) {
        badges.push(
            <div
                key="has-new-message"
                className="badge badge-icon badge-success margin-left-05"
                title="New Message"
                style={{ lineHeight: '11px', fontSize: '22px' }}
            >
                <i className="material-icons md-15">email</i>
            </div>
        );
    }
    if (rowData.is_archived) {
        badges.push(
            <div key="is-archieved" className="badge badge-archived margin-left-05" title="Archived">
                A
            </div>
        );
    }

    if (rowData.is_deleted) {
        badges.push(
            <div key="is-deleted" className="badge badge-grey margin-left-05" title="Deleted">
                D
            </div>
        );
    }

    // TODO, VIKI-7735 make sure feature flag needs_reconciliation is turned on when this is commented in
    if (rowData.last_user_action_failed) {
        let lastFailedUserAction = {
            [CLEARANCE_STATUS.CLEARED]: 'clear',
            [CLEARANCE_STATUS.BLOCKED]: 'block',
            [CLEARANCE_STATUS.DECLINED]: 'decline',
        }[rowData.last_user_action_failed_action_type];

        badges.push(
            <div key="is-deleted" className="material-icons red" title={`Last ${lastFailedUserAction} attempt failed.`}>
                report
            </div>
        );
    }

    if (FEATURE_FLAGS && rowData.last_user_response_email_failed && FEATURE_FLAGS.email_failure_indicator_enabled) {
        badges.push(
            <div key="failed-to-send-last-email" className="material-icons red" title="Failed to send last response email">
                forward_to_inbox
            </div>
        );
    }

    if (rowData.suggested_auto_decline || rowData.suggested_auto_block) {
        let handleClick;

        if (rowData.unactioned_auto_suggested_action && extraData?.currentTab?.id === 'pending_auto_actions') {
            handleClick = e => {
                onClickEvents && onClickEvents['handle-override-auto-action'](e);
            };
        }

        if (rowData.suggested_auto_decline) {
            badges.push(
                <div onClick={handleClick} className="material-icons orange auto-badges" title="Suggesting Auto Decline">
                    credit_card_off
                </div>
            );
        } else if (rowData.suggested_auto_block) {
            badges.push(
                <div onClick={handleClick} className="material-icons pink auto-badges" title="Suggesting Auto Block">
                    work_off
                </div>
            );
        } else {
            console.log('There are no auto actions set.');
        }
    }

    return badges;
}

function extraDuplicateIdContent(value: any, rowData: any, _colId: any) {
    let idVal, colorIndicator, title;
    if (rowData.source === EXTERNAL_SOURCES.VIKI) {
        idVal = `V${rowData.external_id}`;
        colorIndicator = 'info';
        title = 'VIKI';
    } else if (rowData.source === EXTERNAL_SOURCES.ARCH) {
        idVal = `S${rowData.external_id}`;
        colorIndicator = 'grey';
        title = 'Arch';
    } else {
        idVal = rowData.id;
        colorIndicator = 'purple';
        title = 'VMAC';
    }
    return (
        <div key="id-indicator" className={`badge badge-${colorIndicator} w-pre-labels margin-left-05`} title={title}>
            <span>{title}</span> <span>|</span> {idVal}
        </div>
    );
}

const pendingBorExtraDuplicateContent = (value: any, rowData: any, _colId: any) => {
    let colorIndicator, title;
    let val = rowData.id;

    const isRenewalRow = _colId.id === 'rno';

    if (isRenewalRow) {
        if (rowData.renewal_of) val = `V${rowData.renewal_of}`;
        else val = null;
    }

    if (val !== null) {
        if (rowData.source === EXTERNAL_SOURCES.ARCH) {
            colorIndicator = 'grey';
            title = 'Arch';
        } else if (rowData.source === EXTERNAL_SOURCES.VIKI) {
            colorIndicator = 'info';
            title = 'VIKI';
        } else {
            colorIndicator = 'purple';
            title = 'VMAC';
        }
        return (
            <div key="id-indicator" className={`badge badge-${colorIndicator} w-pre-labels margin-left-05`} title={title}>
                <span>{title}</span> <span>|</span> {val}
            </div>
        );
    }
};

const extraSubmissionConflictContent = (_value: any, rowData: any, _col: ColumnType) => {
    let badges = [];
    if (rowData.is_intl_conflict) {
        badges.push(
            <span title="International Conflict" className="material-icons international-conflict">
                flight
            </span>
        );
    }
    return badges;
};

export const COMBINED_TABLE_GLOSSARY: Record<string, TableGlossaryType> = {
    submission: {
        i: { ...ID_COL_PROPS, ...ID_COLOR_HELPER, extraContent: extraIdContent },
        dti: { w: 60, name: 'Days Till Inception' },
        archaname: {
            w: 250,
            readonly: INSURED_READONLY,
            name: 'Insured Name (ArchLink)',
        },
        po: {
            w: 130,
            readonly: SUBMISSION_READONLY,
        },
        iinception: {
            name: 'Inception Date',
            readonly: SUBMISSION_READONLY,
            openToDate: (props: any) => {
                let { inception_date, date_received } = props.currentSubmission;
                return inception_date ? inception_date : moment(date_received).add(DAYS_FROM_RECEIPT, 'days').format('YYYY-MM-DD');
            },
        },
        iexpiration: {
            readonly: SUBMISSION_READONLY,
        },
        s: {
            readonly: true, // keep readonly because if you change status it needs to switch tabs in the grid
            w: 115,
            extraContent: extraStatusContent,
            cellDataGetter: (value: any, rowData: any, _colId: string) => {
                if (rowData === undefined) return;
                const isIncumbentSubmission = rowData.bor_incumbent_submission_id === String(rowData.id);
                const incumbentStatus = isIncumbentSubmission ? 'I' : '';
                const pendingBorStatus =
                    rowData.pending_bor_status && ['P', 'L'].includes(rowData.pending_bor_status) ? `${rowData.pending_bor_status}${incumbentStatus}` : '';
                return `${pendingBorStatus}${value}`;
            },
        },
        bs: {
            readonly: SUBMISSION_READONLY,
        },
        dr: {
            w: 150,
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        hs: {
            readonly: SUBMISSION_READONLY,
        },
        rte: {
            readonly: REPLY_TO_READONLY,
        },
        pan: {
            w: 130,
            readonly: BROKER_READONLY,
        },
        pai: {
            readonly: true,
        },
        pppcid: {
            readonly: true,
        },
        inb: {
            readonly: SUBMISSION_READONLY,
        },
        ibd: {
            readonly: SUBMISSION_READONLY,
        },
        irate: {
            readonly: SUBMISSION_READONLY,
        },
        itarget: {
            readonly: SUBMISSION_READONLY,
        },
        iname: {
            readonly: INSURED_READONLY,
        },
        istreet: {
            w: 200,
            readonly: INSURED_READONLY,
        },
        icity: {
            readonly: INSURED_READONLY,
        },
        istate: {
            readonly: INSURED_READONLY,
        },
        iz: {
            readonly: INSURED_READONLY,
        },
        if: {
            readonly: INSURED_READONLY,
        },
        pppc: {
            readonly: true,
        },
        uwpf: {
            readonly: SUBMISSION_READONLY,
        },
        rui: {
            readonly: SUBMISSION_READONLY,
            // VIKI-7451 & VIKI-8877
            constantsFilter: (choices: [string, string, boolean, Array<number>, boolean][], dataObject: any, selectedValue: any, extraData: any) => {
                if (extraData) {
                    // need to filter available UW list by subdivision
                    // VIKI-8877
                    const { featureFlags } = extraData;
                    if (!featureFlags || !featureFlags.underwriter_filter_by_subdivision_enabled) {
                        // VIKI-8877
                        return choices;
                    }
                }

                let filterBySubdivisionId = null;
                if (dataObject) {
                    if (dataObject.underwriting_platform === UnderwritingPlatformType.VIKI) {
                        filterBySubdivisionId = SUBDIVISION_CODES.VENTUS_HO;
                    } else if (dataObject.underwriting_platform === UnderwritingPlatformType.POLICYWRITER) {
                        filterBySubdivisionId = SUBDIVISION_CODES.ES_PROPERTY;
                    } else {
                        console.error('Unknown underwriting_platform selected.');
                    }
                }

                if (_.isNull(filterBySubdivisionId)) {
                    return choices;
                }

                return choices.filter(c => {
                    return c[3].indexOf(filterBySubdivisionId) !== -1 && c[4] !== true;
                });
            },
        },
        oui: {
            readonly: SUBMISSION_READONLY,
        },
        fe: {
            readonly: true,
        },
        type: {
            name: 'Created',
            readonly: true,
        },
        bldgs: {
            name: 'Created',
            readonly: true,
        },
        tiv: {
            name: 'Created',
            readonly: true,
            field_format: { subtype: 'currency' },
        },
        archloc: {
            field_format: { subtype: 'badge' },
            field_name: 'arch_account_passes_ofac_checks',
            name: 'OFAC',
        },
        apoc: {
            field_format: { subtype: 'badge' },
            name: 'OFAC Check',
            cellDataGetter: (_: any, submission: any) => {
                return submission && submission.arch_account_passes_ofac_checks;
            },
        },
        inp: {
            neverEditable: true,
        },
        panp: {
            neverEditable: true,
        },
        pid: {
            readonly: SUBMISSION_READONLY,
        },
        lai: {
            readonly: SUBMISSION_READONLY,
            // choicesHelper: (choices: [string, string][], choicesHelperData: any) => {
            //     return choices.map((c) => {
            //         const states = choicesHelperData[c[0]];
            //         if (states && states.length) {
            //             return [c[0], `${c[1]}: [${states.join('] [')}]`];
            //         }
            //         return c;
            //     });
            // },
            // choicesHelperConstantsData: 'PRODUCING_AGENT_LICENSE_STATES',
        },
        exsid: {
            field_format: { nocommas: true },
        },
        besid: {
            field_format: { nocommas: true, subtype: 'button' },
            onClick: (props: any) => {
                let { ENV, currentSubmission } = props;
                if (!currentSubmission || !currentSubmission.blocking_external_submission_id) {
                    return null;
                }
                window.open(getBlockingExternalSubmissionUrl(currentSubmission, ENV), '_blank');
            },
            cellDataGetter: (blocking_external_submission_id: any, currentSubmission: any) => {
                let { blocking_external_submission_source } = currentSubmission;
                if (blocking_external_submission_source === EXTERNAL_SOURCES.ARCH) {
                    return `S${blocking_external_submission_id}`;
                }
                return blocking_external_submission_id;
            },
        },
        proimod: {
            field_format: { nocommas: true },
        },
        paiobjid: {
            field_format: { nocommas: true },
        },
        cinid: {
            field_format: { nocommas: true },
        },
        inid: {
            field_format: { nocommas: true },
        },
        ciei: {
            field_format: { nocommas: true },
        },
        atid: {
            name: 'Assigned To',
            nullLabel: 'Unassigned',
            nullable: true,
            constants_choices: 'CLEARANCE_USERS',
            readonly: (rowData: Record<string, any>, extraData: Record<string, any>) => !extraData || !extraData.me || !extraData.me.can_assign_submissions,
        },
        arf: {
            name: 'Research',
            readonly: SUBMISSION_READONLY,
        },
        lsbro: {
            name: 'Last SOV Bot Received',
            cellType: 'datetime',
            field_name: 'last_sov_bot_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        iloro: {
            name: 'Last OFAC Received',
            cellType: 'datetime',
            field_name: 'arch_account_last_ofac_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        last_archlink_ofac_check_at: {
            name: 'OFAC Last Run On',
            type: 'datetime',
            field_name: 'last_archlink_ofac_check_at',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
            cellDataGetter: (_: any, submission: any) => {
                if (submission.arch_clearance_api_account_status) {
                    return submission.last_archlink_ofac_check_at;
                }
                return submission.arch_account_last_ofac_received_on;
            },
        },
        arch_account_last_ofac_submit_status: {
            name: 'OFAC Run Status',
            field_name: 'arch_account_last_ofac_submit_status',
            cellDataGetter: (_: any, submission: any) => {
                const capitalize = (s: string) => (s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : s);
                let run_status = submission.arch_account_last_ofac_submit_status;
                if (submission.arch_clearance_api_account_status) {
                    run_status = submission.arch_clearance_api_account_status.toLowerCase() !== 'suspended' ? submission.arch_clearance_api_account_status : '';
                }
                return capitalize(run_status);
            },
        },
        arch_account_last_ofac_submit_by_name: {
            name: 'OFAC Run By',
            field_name: 'arch_account_last_ofac_submit_by_name',
            cellDataGetter: (submit_by: any, submission: any) => {
                if (submission.arch_clearance_api_account_status && submission.arch_clearance_api_account_status.toLowerCase() === 'suspended') {
                    return '';
                }
                return submit_by || submission.arch_clearance_api_account_status ? 'ArchLink' : 'VMAC';
            },
        },
        last_sov_bot_received_on: {
            name: 'SOV Bot Last Run On',
            type: 'datetime',
            field_name: 'last_sov_bot_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        arch_clearance_api_change_on: {
            name: 'ARCH Local Field Change On',
            type: 'datetime',
            field_name: 'arch_clearance_api_change_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        arch_clearance_api_received_on: {
            name: 'ARCH Response Received On',
            type: 'datetime',
            field_name: 'arch_clearance_api_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        bp: {
            name: 'Bind Propensity',
            rating: true,
        },
        pbs: {
            name: 'BOR Status',
            readonly: true,
        },
        pbsid: {
            w: 100,
            name: 'Pending BOR Incumbent ID',
            field_format: { nocommas: true },
        },
        ibid: {
            name: 'Incumbent Broker',
            type: 'text',
            readonly: true,
            field_name: 'bor_incumbent_broker',
        },
        nbid: {
            name: 'New Broker',
            type: 'text',
            readonly: true,
        },
        sova: {
            name: 'SOV Absent',
            readonly: true,
        },
    },
    brokermatches: {
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        e: {
            w: 200,
            field_name: 'email',
            short_name: 'Email',
        },
        c: {
            w: 300,
            field_name: 'parent.parent_company',
            short_name: 'Company',
            cellDataGetter: (parent_company: any) => {
                return (parent_company && parent_company.name) || UNKNOWN;
            },
        },
        ru: {
            w: 250,
            field_name: 'parent',
            short_name: 'Underwriter',
            cellDataGetter: (parent: any) => {
                const requestedUnderwriter = parent.requested_underwriter;
                return requestedUnderwriter ? requestedUnderwriter.full_name : '';
            },
        },
        o: {
            w: 250,
            field_name: 'parent',
            short_name: 'Office',
            cellDataGetter: (parent: any) => {
                return (parent && parent.office) || UNKNOWN;
            },
        },
        sel: SELECT_COL_PROPS,
    },
    duplicatesubmissions: {
        i: {
            w: 140,
            field_name: 'id',
            short_name: 'ID',
            extraContent: extraDuplicateIdContent,
            cellDataGetter: () => {
                return '';
            },
        },
        sd: {
            w: 90,
            field_name: 'status_display',
            short_name: 'Status',
            extraContent: extraStatusContent,
            cellDataGetter: (val: string, row: any) => {
                // VIKI-8771
                if (row.source === EXTERNAL_SOURCES.VIKI || row.source === EXTERNAL_SOURCES.ARCH) {
                    return row.imported_status_display || row.status_display;
                }
                return row.status_display;
            },
        },
        src: {
            w: 80,
            field_name: 'source',
            short_name: 'Source',
        },
        inc: {
            w: 75,
            field_name: 'inception_date',
            short_name: 'Inc.',
            cellType: 'date',
        },
        exp: {
            w: 75,
            field_name: 'expiration_date',
            short_name: 'Exp.',
            cellType: 'date',
        },
        dtr: {
            w: 150,
            field_name: 'date_received',
            short_name: 'Date/Time',
            cellType: 'datetime',
        },
        dti: {
            w: 50,
            field_name: 'days_till_inception',
            short_name: 'DTI',
        },
        b: {
            w: 150,
            field_name: 'account_agent_name',
            short_name: 'Acct Broker',
        },
        bco: {
            w: 125,
            field_name: 'office_parent_company_short_name',
            short_name: 'Broker Co.',
        },
        bldc: {
            w: 85,
            field_name: 'sov_building_count',
            short_name: '# Bldgs',
        },
        tiv: {
            w: 100,
            field_name: 'sov_total_tiv',
            short_name: 'TIV',
            type: 'number',
            field_format: {
                subtype: 'currency',
                decimals: 0,
            },
        },
        cn: {
            w: 250,
            short_name: 'Client',
            field_name: 'arch_account_name',
            toolTipFn: (row: any) => {
                return row.arch_account_name || '';
            },
        },
        cstreet: {
            w: STREET.w,
            short_name: 'Street',
            field_name: 'arch_account_street',
            toolTipFn: (row: any) => {
                return row.arch_account_street || '';
            },
        },
        ccity: {
            w: CITY.w,
            short_name: 'City',
            field_name: 'arch_account_city',
            toolTipFn: (row: any) => {
                return row.arch_account_city || '';
            },
        },
        cstate: {
            w: STATE.w,
            short_name: 'ST',
            field_name: 'arch_account_state',
        },
        czipcode: {
            w: ZIPCODE.w,
            short_name: 'Zip',
            field_name: 'arch_account_zipcode',
        },
        cfein: {
            w: FEIN.w,
            short_name: 'FEIN',
            field_name: 'arch_account_fein',
        },
        a: {
            w: 85,
            field_name: 'actions',
            name: 'Actions',
            cellType: 'actions',
            actions: [
                {
                    text: 'GO TO',
                    actionFn: 'onGoToDupe',
                },
            ],
        },
        uwbd: {
            w: 100,
            field_name: 'uw_business_division',
            short_name: 'Division',
            constants_choices: 'BUSINESS_DIVISION_CODES',
        },
        uwsd: {
            w: 100,
            field_name: 'uw_sub_division',
            short_name: 'Sub Division',
            constants_choices: 'BUSINESS_SUB_DIVISION_CODES',
        },
    },
    clientmatches: {
        n: {
            w: 350,
            field_name: 'name',
            short_name: 'Insured Name',
            toolTipFn: (row: any) => {
                return row.name || '';
            },
            readonly: INSURED_READONLY_CLIENT_MATCHING,
        },
        st: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...STREET },
        c: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...CITY },
        s: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...STATE },
        z: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...ZIPCODE },
        f: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...FEIN },
        ioi: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...IMPORT_OBJECT_ID },
        aid: {
            readonly: INSURED_READONLY_CLIENT_MATCHING,
            field_name: 'arch_id',
            short_name: 'Arch ID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=${getDomainIdForEnv(
                        extraData.env
                    )}&AppID=${getAccountNumberFromString(rowData.arch_id)}&ExternalKey=A${rowData.arch_id}`,
                };
            },
        },
        as: {
            readonly: INSURED_READONLY_CLIENT_MATCHING,
            field_name: 'status_verbose',
            short_name: 'Status',
            w: 100,
        },

        sel: SELECT_COL_PROPS,
        sela: SELECT_ARCH_ACCOUNT_COL_PROPS,
        subs: {
            w: 50,
            field_name: 'submission',
            short_name: 'VIKI SID',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && last_bound_submission.id;
            },
        },
        id: {
            field_name: 'inception_date',
            short_name: 'Inception Date',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && last_bound_submission.inception_date;
            },
        },
        if: {
            field_name: 'in_force',
            short_name: 'In-Force',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && (last_bound_submission.in_force ? 'Yes' : 'No');
            },
        },
        dtr: {
            field_name: 'date_time_received',
            short_name: 'Date/Time Received',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                const date = last_bound_submission && last_bound_submission.date_received;
                return date ? moment(date).format(DISPLAY_DATE_TIME_AM_PM_S) : '';
            },
        },
    },
    borsubmissionmatches: {
        i: {
            w: 140,
            field_name: 'id',
            short_name: 'ID',
            extraContent: pendingBorExtraDuplicateContent,
            cellDataGetter: () => {
                return '';
            },
        },
        src: {
            w: 80,
            field_name: 'source',
            short_name: 'Source',
        },
        sd: {
            w: 115,
            field_name: 'status_display',
            short_name: 'Status',
            extraContent: extraStatusContent,
        },
        inc: {
            w: 100,
            field_name: 'inception_date',
            short_name: 'Inc.',
            cellType: 'date',
        },
        exp: {
            w: 100,
            field_name: 'expiration_date',
            short_name: 'Exp.',
            cellType: 'date',
        },
        dtr: {
            w: 150,
            field_name: 'date_received',
            short_name: 'Date/Time',
            cellType: 'datetime',
        },
        dti: {
            w: 50,
            field_name: 'days_till_inception',
            short_name: 'DTI',
        },
        rno: {
            w: 150,
            field_name: 'renewal_of',
            short_name: 'Renewal Of',
            extraContent: pendingBorExtraDuplicateContent,
            cellDataGetter: () => {
                return '';
            },
        },
        agnt: {
            w: 150,
            field_name: 'agent',
            short_name: 'Agent',
        },
        proc: {
            w: 300,
            field_name: 'producer_company',
            short_name: 'Producer Company',
        },
        cn: {
            w: 350,
            short_name: 'Client',
            field_name: 'arch_account_name',
        },
        a: {
            w: 160,
            field_name: 'actions',
            name: 'Actions',
            cellType: 'actions',
            actions: [
                {
                    text: (rowData: any, extraData: any) => {
                        let action = '';
                        if (rowData.id === extraData.currentSubmission.id || rowData.renewal_of) {
                            action = '';
                        } else {
                            if (extraData.borSubmissions.includes(rowData.id)) {
                                action = 'Remove';
                            } else {
                                action = 'Add';
                            }
                        }
                        return action;
                    },
                    actionFn: 'onMarkPendingBor',
                },
            ],
        },
    },
    accountconflicts: {
        sel: SELECT_ARCH_COL_PROPS,
        an: {
            w: 100,
            field_name: 'accountNumber',
            short_name: 'Account Number',
            urlFn: (rowData: any, extraData: any = {}) =>
                rowData.accountNumber === 'NEW'
                    ? { target: '_blank', url: '#' }
                    : {
                          target: '_blank',
                          url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=2&AppID=${getAccountNumberFromString(
                              rowData.accountNumber
                          )}&ExternalKey=${rowData.accountNumber}`,
                      },
        },
        expdt: {
            w: 125,
            field_name: 'expiration_date',
            short_name: 'Expiration',
        },
        addr: {
            w: 250,
            field_name: 'address1',
            short_name: 'Street',
        },
        addr1: {
            w: 175,
            field_name: 'address2',
            short_name: 'Address (2)',
        },
        c: {
            w: 100,
            field_name: 'city',
            short_name: 'City',
        },
        s: {
            w: 100,
            field_name: 'state',
            short_name: 'State',
        },
        ct: {
            w: 100,
            field_name: 'conflictType',
            short_name: 'Conflict Type',
        },
        st: {
            w: 100,
            field_name: 'accountStatus',
            short_name: 'Status',
        },
        effd: {
            w: 100,
            field_name: 'effectiveDate',
            short_name: 'Effective Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        expd: {
            w: 100,
            field_name: 'expirationDate',
            short_name: 'Expiration Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        sid: {
            w: 100,
            field_name: 'submissionNumber',
            short_name: 'SID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
                        rowData.submissionNumber
                    )}`,
                };
            },
        },
        z: {
            w: 100,
            field_name: 'zipcode',
            short_name: 'Zipcode',
        },
    },
    conflictingsubmissions: {
        an: {
            w: 100,
            field_name: 'accountNumber',
            short_name: 'Account Number',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=2&AppID=${getAccountNumberFromString(
                        rowData.accountNumber
                    )}&ExternalKey=${rowData.accountNumber}`,
                };
            },
            extraContent: extraSubmissionConflictContent,
        },
        pas: {
            w: 100,
            field_name: 'policyAdminSystem',
            short_name: 'PAS',
        },
        addr: {
            w: 175,
            field_name: 'address1',
            short_name: 'Address',
        },
        addr1: {
            w: 125,
            field_name: 'address2',
            short_name: 'Address (2)',
        },
        c: {
            w: 100,
            field_name: 'city',
            short_name: 'City',
        },
        s: {
            w: 100,
            field_name: 'state',
            short_name: 'State',
        },
        ct: {
            w: 100,
            field_name: 'conflictType',
            short_name: 'Conflict Type',
        },
        effd: {
            w: 100,
            field_name: 'effectiveDate',
            short_name: 'Effective Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        expd: {
            w: 100,
            field_name: 'expirationDate',
            short_name: 'Expiration Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        sid: {
            w: 100,
            field_name: 'submissionNumber',
            short_name: 'SID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
                        rowData.submissionNumber
                    )}`,
                };
            },
        },
        z: {
            w: 100,
            field_name: 'zipcode',
            short_name: 'Zipcode',
        },
        div: {
            w: 100,
            field_name: 'division',
            short_name: 'Division',
            constants_choices: 'BUSINESS_DIVISION_CODES',
        },
        sdiv: {
            w: 100,
            field_name: 'subDivision',
            short_name: 'Sub Division',
            // constants_choices: 'BUSINESS_SUB_DIVISION_CODES',
        },
    },
    deltarecords: {
        i: {
            w: 50,
            field_name: 'id',
            short_name: 'ID',
        },
        cn: {
            w: 200,
            field_name: 'change_new',
            short_name: 'New',
        },
        co: {
            w: 200,
            field_name: 'change_old',
            short_name: 'Old',
        },
        cr: {
            w: 160,
            field_name: 'change_reason',
            short_name: 'Data Source',
            constants_choices: 'DELTA_RECORD_SOURCE',
        },
        cf: {
            w: 200,
            field_name: 'change_field',
            short_name: 'Field',
        },
        cs: {
            w: 115,
            field_name: 'changes',
            short_name: 'Changes',
        },
        ovn: {
            w: 200,
            field_name: 'object_verbose_name',
            short_name: 'Object',
        },
        oi: {
            w: 115,
            field_name: 'object_id',
            short_name: 'Object ID',
        },
        hcr: {
            w: 115,
            field_name: 'history_change_reason',
            short_name: 'Change Reason',
        },
        hun: {
            w: 200,
            field_name: 'history_user__name',
            short_name: 'User',
            cellDataGetter: (user: any) => {
                if (!user) {
                    return 'VMAC';
                }
                return user;
            },
        },
        ha: {
            w: 115,
            field_name: 'history_action',
            short_name: 'Action',
        },
        hu: {
            w: 200,
            field_name: 'history_user',
            short_name: 'User',
        },
        hd: {
            w: 130,
            field_name: 'history_date',
            short_name: 'Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        had: {
            w: 130,
            field_name: 'history_action_display',
            short_name: 'Action',
        },
        hcrd: {
            w: 130,
            field_name: 'history_change_reason_display',
            short_name: 'Data Source',
        },
    },
};
